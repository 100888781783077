import { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { animationSlidesSelector } from 'app/redux/animation/selectors'
import useAudio from 'app/hooks/useAudio'
import './useBackgroundAudio.css'

const useBackgroundAudio = (
  slide: number,
  needToPlay: boolean,
  displayCustomAudioControlCursor = false,
  isWhiteControl = false
) => {
  const slides = useSelector(animationSlidesSelector)
  const prevIsPlayingRef = useRef(false)

  const { isPlaying, readyToPlay, play, pause } = useAudio(
    slides.find(({ slideNumber }) => slideNumber === slide)?.audio ?? '',
    {
      loop: true,
      volume: 0.5,
    }
  )

  useEffect(() => {
    if (!readyToPlay) {
      return
    }

    if (needToPlay && prevIsPlayingRef.current) {
      play(true)
    } else {
      pause(true)
    }
  }, [readyToPlay, needToPlay])

  useEffect(() => {
    if (!readyToPlay || !needToPlay || !displayCustomAudioControlCursor) {
      return
    }

    const handlePlay = ({ target }) => {
      const cursor = getComputedStyle(target).getPropertyValue('cursor')

      if (!cursor.includes('sound-on')) {
        return
      }

      play(true)
    }

    const handlePause = ({ target }) => {
      const cursor = getComputedStyle(target).getPropertyValue('cursor')

      if (!cursor.includes('sound-off')) {
        return
      }

      pause()
    }

    if (isPlaying) {
      document.body.classList.remove('sound-on')
      document.body.classList.add('sound-off')
      document.addEventListener('click', handlePause)
    } else {
      document.body.classList.add('sound-on')
      document.body.classList.remove('sound-off')
      document.addEventListener('click', handlePlay)
    }

    if (isWhiteControl) {
      document.body.classList.add('white')
    }

    prevIsPlayingRef.current = isPlaying

    return () => {
      document.body.classList.remove('sound-on')
      document.body.classList.remove('sound-off')
      document.removeEventListener('click', handlePlay)
      document.removeEventListener('click', handlePause)

      if (isWhiteControl) {
        document.body.classList.remove('white')
      }
    }
  }, [
    readyToPlay,
    needToPlay,
    isPlaying,
    displayCustomAudioControlCursor,
    isWhiteControl,
  ])

  return {
    isPlaying,
    readyToPlay,
    play,
    pause,
  }
}

export default useBackgroundAudio
