import { Link } from 'react-router-dom'
import cn from 'classnames'
import routes from 'app/routes'
import classes from './Home.module.scss'

const Home = () => {
  return (
    <div className={classes.page}>
      <div className={classes.page_inner}>
        <header className={classes.header}>
          <h1 className={classes.title}>
            Добро пожаловать на платформу «Приразломная»
          </h1>
          <span className={classes.subtitle}>
            Выберите билет
          </span>
        </header>
        <div className={classes.tickets}>
          <Link to={routes.kids} className={cn(classes.ticket, classes.children)}>
            <div className={classes.overlay} />
            <div className={cn(classes.ticket_inner, classes.children)} />
          </Link>
          <Link to={routes.main} className={cn(classes.ticket, classes.adult)}>
            <div className={classes.overlay} />
            <div className={cn(classes.ticket_inner, classes.adult)} />
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Home
