import { useEffect } from 'react'

const useHideScrollbar = (element: HTMLElement , isHidden: boolean) => {
  useEffect(() => {
    element.style.overflow = isHidden ? 'hidden' : ''

    return () => {
      element.style.overflow = ''
    }
  }, [element, isHidden])
}

export default useHideScrollbar
