import { FC } from 'react'
import cn from 'classnames'
import useFindParentSlide from 'app/hooks/useFindParentSlide'
import Platform from 'app/components/Platform'
import classes from './PlatformModal.module.scss'

type PlatformModalProps = {
  visible: boolean
  onSelect: (id: string) => void
  onClose: () => void
}

const PlatformModal: FC<PlatformModalProps> = ({ visible, onSelect, onClose }) => {
  const { findParentSlide } = useFindParentSlide()

  const handleSelect = (ids: Array<string>) => {
    const nextSlide = findParentSlide(ids)

    if (!nextSlide) {
      return
    }

    onSelect(nextSlide.id)
  }

  return (
    <div className={cn(classes.modal, {
      [classes.hidden]: !visible
    })}>
      <div className={classes.controls}>
        <button className={classes.control} onClick={onClose}>
          Назад
        </button>
      </div>
      <Platform onSelect={handleSelect} />
    </div>
  )
}

export default PlatformModal
