import { FC, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import cn from 'classnames'
import PrettyScrollbars from 'app/components/PrettyScrollbars/PrettyScrollbars'
import VideoViewer from 'app/components/VideoViewer/VideoViewer'
import { activateSlide } from 'app/redux/slider/actions'
import {
  activeSlideSelector,
  sortedSlidesSelector,
} from 'app/redux/slider/selectors'
import useFindParentSlide from 'app/hooks/useFindParentSlide'
import Platform from 'app/components/Platform'
import AudioGuide from './AudioGuide/AudioGuide'
import classes from './SlideModal.module.scss'

type SlideModalProps = {
  onClose: () => void
}

const SlideModal: FC<SlideModalProps> = ({ onClose }) => {
  const slides = useSelector(sortedSlidesSelector)
  const activeSlide = useSelector(activeSlideSelector)

  const navRef = useRef<HTMLDivElement>()
  const [isNavOpen, setIsNavOpen] = useState(false)
  const [scaling, setScaling] = useState(1)

  useEffect(() => {
    if (!isNavOpen) {
      return
    }

    const handleClick = e => {
      if (!navRef.current) {
        return
      }

      if (navRef.current.contains(e.target)) {
        return
      }

      setIsNavOpen(false)
    }

    document.addEventListener('click', handleClick)

    return () => {
      document.removeEventListener('click', handleClick)
    }
  }, [isNavOpen])

  useEffect(() => {
    setScaling(window.screen.width / 1920)

    window.addEventListener('resize', () => {
      setScaling(window.screen.width / 1920)
    })
    return (
      window.removeEventListener('resize', () => {
        setScaling(window.screen.width / 1920)
      }))

  }, [window.screen.width])

  const handleToggleNavOpen = () => {
    setIsNavOpen(isNavOpen => !isNavOpen)
  }

  const handleCloseNav = () => {
    setIsNavOpen(false)
  }

  const dispatch = useDispatch()

  const handleActivateSlide = (id: string) => {
    dispatch(activateSlide(id))
  }

  const { findParentSlide } = useFindParentSlide()

  const handleSelect = (ids: Array<string>) => {
    const nextSlide = findParentSlide(ids)

    if (!nextSlide) {
      return
    }

    dispatch(activateSlide(nextSlide.id))
  }

  return (
    <div className={classes.slides_page}>
      <AudioGuide src={activeSlide.audio_kids} />
      <div className={classes.body}>
        <div className={classes.left}>
          <div className={classes.title}>{activeSlide.title}</div>
          <div className={classes.platform}>
            <div className={classes.platform_inner}
              style={{ transform: `scale(${scaling}) translateX(-50%)` }}
            >
              <Platform size='small' onSelect={handleSelect} />
            </div>
          </div>
          <div className={classes.description}>
            <PrettyScrollbars hide autoScroll>
              <p>
                {activeSlide.text_kids ||
                  'Здесь еще пока нет описания, но скоро будет'}
              </p>
            </PrettyScrollbars>
          </div>
        </div>
        <div className={classes.right}>
          <div className={classes.slider_back_control}>
            <button className={classes.slider_back_control_btn} onClick={onClose}>
              Назад
            </button>
          </div>
          <div className={classes.slider_block}>
            <VideoViewer src={activeSlide.url} />
          </div>
          <div className={classes.slider_footer}>
            <div className={classes.slider_nav_container}>
              <button
                className={classes.slider_nav_btn}
                onClick={handleToggleNavOpen}
              >
                Сменить точку обзора
              </button>
              {isNavOpen && (
                <div ref={navRef} className={classes.slider_nav}>
                  <PrettyScrollbars hide autoScroll>
                    <div className={classes.slider_nav_inner}>
                      {slides.map(({ id, title }) => (
                        <button
                          key={id}
                          className={cn(classes.slider_nav_item, {
                            [classes.active]: id === activeSlide.id,
                          })}
                          onClick={() => {
                            handleActivateSlide(id)
                            handleCloseNav()
                          }}
                        >
                          {title}
                        </button>
                      ))}
                    </div>
                  </PrettyScrollbars>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SlideModal
