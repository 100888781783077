import { createSelector } from 'reselect'
import { RootState } from 'app/redux/store'

export const slidesSelector = (state: RootState) => state.slider.data

export const sortedSlidesSelector = createSelector(slidesSelector, slides => {
  return slides.sort((a, b) => (a.title + a.id > b.title + b.id ? 1 : -1))
})

export const activeSlideSelector = createSelector(slidesSelector, slides => {
  return slides.find(({ active }) => active)
})

export const sortedInternalRoomsSlidesSelector = createSelector(sortedSlidesSelector, slides => {
  return slides.filter(({ is_internal }) => is_internal)
})

export const sortedExternalRoomsSlidesSelector = createSelector(sortedSlidesSelector, slides => {
  return slides.filter(({ is_internal }) => !is_internal)
})
