import { KeyboardEvent, useEffect } from 'react'

type Options = {
  withMetaKey?: boolean
}

const useKeyDown = (
  code: string,
  onKeyDown: () => void,
  { withMetaKey = false }: Options = {}
) => {
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (withMetaKey && !e.metaKey) {
        return
      }

      if (e.code !== code) {
        return
      }

      onKeyDown()
    }

    // @ts-expect-error bad typing
    window.addEventListener('keydown', handleKeyDown)

    return () => {
      // @ts-expect-error bad typing
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [code, withMetaKey, onKeyDown])
}

export default useKeyDown
