import { FC, useLayoutEffect, useRef } from 'react'
import { MainModel } from '3d/base/MainModel'

type PlatformProps = {
    size?: 'full' | 'small'
    onSelect?: (ids: Array<string>) => void
}

const Platform: FC<PlatformProps> = ({ size = 'full', onSelect }) => {
    const modelRef = useRef(new MainModel())

    useLayoutEffect(() => {
        if (size === 'small') {
            modelRef.current.enter({
                width: window.outerWidth > 1200 ? 500 : 300,
                height: window.outerWidth > 1200 ? 350 : 200,
                fieldOfView: 45,
                aspect: 1.45,
                minControlsDistance: 200,
                maxControlsDistance: 200,
                onSelect,
                addOffsetLeft:100
            })
            return
        }

        modelRef.current.enter({
            fieldOfView: 45,
            aspect: window.innerWidth / window.innerHeight,
            minControlsDistance: 220,
            maxControlsDistance: 300,
            onSelect,
            addOffsetLeft:0
        })

        return  () => {
            modelRef.current.exit().then(() => {
                modelRef.current = null
            })
        }
    }, [])

    return <div id='gl_container'></div>
}

export default Platform
