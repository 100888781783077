import { FC, useState, useEffect, useRef } from 'react';
import classes from './Preloader.module.scss';

type PreloaderProps = {
	duration: number;
};

const Preloader: FC<PreloaderProps> = ({ duration }) => {
	const [progress, setProgress] = useState(0);
	const progressbarRef = useRef<HTMLDivElement>(null);

	const timeFraction = duration / 100;

	let timer

	const startTimer = () => {
		timer = setInterval(() => {
			setProgress(prevState => {
				if (prevState === 100) {
					clearInterval(timer)
				} else {
					prevState++
				}

				return prevState
			});
		}, timeFraction);
	}

	useEffect(() => {
		startTimer()

		// return () => clearTimer();
	}, []);

	useEffect(() => {
		progressbarRef.current.style.width = `${progress}%`;

	}, timer);

	return (
		<div className={classes.preloader}>
			<div className={classes.text}>
				Морская добыча — технологически сложный процесс, поэтому разведка
				арктического шельфа в стране началась только в конце 1970-х годов.
				<br />
				<br />
				Наличие промышленных запасов нефти в районе Приразломного было
				подтверждено в 1989 году. Для разработки месторождения была построена
				специальная морская ледостойкая стационарная платформа (МЛСП), которая
				получила имя месторождения —
				<br />
				«Приразломная».
			</div>
			<div className={classes.indicator}>
				<div className={classes.progressbar}>
					<div ref={progressbarRef} className={classes.progressbar_inner} style={{ width: `${progress}%` }}></div>
				</div>
				<div className={classes.indicator_text}>{progress}%</div>
			</div>
		</div>
	);
};

export default Preloader;