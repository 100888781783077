import { useRef, useEffect, useState } from 'react'
import WaveSurfer from 'wavesurfer.js'

type Options = {
  height?: number
  progressColor?: string
}

const useAudioGuide = (src: string, options: Options = {}) => {
  const { height, progressColor } = options

  const waveformContainerRef = useRef<HTMLDivElement>(null)
  const waveformRef = useRef<WaveSurfer>(null)
  const [isPlaying, setIsPlaying] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [duration, setDuration] = useState(0)
  const [progress, setProgress] = useState(0)

  useEffect(() => {
    if (!waveformContainerRef.current) {
      return
    }

    waveformRef.current = WaveSurfer.create({
      container: waveformContainerRef.current,
      barWidth: 1,
      barHeight: height ?? 1,
      progressColor: progressColor ?? null,
      maxCanvasWidth: 300,
      cursorColor: 'transparent',
      hideScrollbar: true,
    })

    return () => {
      waveformRef.current.destroy()
    }
  }, [])

  useEffect(() => {
    setIsPlaying(false)
  }, [src])

  useEffect(() => {
    if (!waveformRef.current) {
      return
    }

    waveformRef.current.load(src)

    setIsLoading(true)

    const handleLoaded = () => {
      setIsLoading(false)
      setDuration(Math.floor(waveformRef.current.getDuration()))
    }

    waveformRef.current.on('ready', handleLoaded)

    return () => {
      waveformRef.current.un('ready', handleLoaded)
    }
  }, [src])

  useEffect(() => {
    if (!waveformRef.current) {
      return
    }

    const handleProgress = () => {
      setTimeout(() => {
        setProgress(Math.floor(waveformRef.current.getCurrentTime()))
      }, 0)
    }

    waveformRef.current.on('audioprocess', handleProgress)
    waveformRef.current.on('interaction', handleProgress)

    return () => {
      waveformRef.current.un('audioprocess', handleProgress)
      waveformRef.current.un('interaction', handleProgress)
    }
  }, [src])

  useEffect(() => {
    if (!waveformRef.current) {
      return
    }

    const handleFinish = () => {
      setIsPlaying(false)
    }

    waveformRef.current.on('finish', handleFinish)

    return () => {
      waveformRef.current.un('finish', handleFinish)
    }
  }, [src])

  const handlePlay = () => {
    if (!waveformRef.current || isLoading) {
      return
    }

    waveformRef.current.play()
    setIsPlaying(true)
  }

  const handlePause = () => {
    if (!waveformRef.current) {
      return
    }

    waveformRef.current.pause()
    setIsPlaying(false)
  }

  return {
    waveformContainerRef,
    isPlaying,
    isLoading,
    duration,
    progress,
    handlePlay,
    handlePause,
  }
}

export default useAudioGuide
