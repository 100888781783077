import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { activateSlide } from 'app/redux/slider/actions'
import {
  sortedInternalRoomsSlidesSelector,
  sortedExternalRoomsSlidesSelector,
} from 'app/redux/slider/selectors'
import { animationSlidesSelector } from 'app/redux/animation/selectors'
import useBackgroundAudio from './hooks/useBackgroundAudio'
import AssetsPreloader from './AssetsPreloader'
import Preloader from './Preloader/Preloader'
import SlideText from './SlideText/SlideText'
import Animation from './Animation/Animation'
import Menu from './Menu/Menu'
import { Sitemap } from './Sitemap/Sitemap'
import SlideModal from './SlideModal/SlideModal'
import Header from './Header/Header'
import classes from './Children.module.scss'
import BgAudioControl from './BgAudioControl/BgAudioControl'
import PlatformModal from './PlatformModal/PlatformModal'

const Children: FC = () => {
  const [isAssetsLoading, setIsAssetsLoading] = useState(true)
  const [assetsLoadingProgress, setAssetsLoadingProgress] = useState(0)
  const [isModalOpened, setIsModalOpened] = useState(false)
  const [isSitemapOpened, setIsSitemapOpened] = useState(false)
  const [isAudioControlCursorDisplay, setIsAudioControlCursorDisplay] =
    useState(true)
  const [isWhiteAudioControlCursor, setIsWhiteAudioControlCursor] =
    useState(true)
  const [isModelDisplay, setIsModelDisplay] = useState(false)
  const [currentSlide, setCurrentSlide] = useState(0)
  const [slideText, setSlideText] = useState('')
  const slides = useSelector(animationSlidesSelector)
  const internalRoomsSlides = useSelector(sortedInternalRoomsSlidesSelector)
  const externalRoomsSlides = useSelector(sortedExternalRoomsSlidesSelector)

  const { isPlaying, play, pause } = useBackgroundAudio(
    currentSlide,
    !isModalOpened && !isAssetsLoading && currentSlide > 0,
    isAudioControlCursorDisplay,
    isWhiteAudioControlCursor
  )

  useEffect(() => {
    setIsWhiteAudioControlCursor(currentSlide === 0)
    setIsAudioControlCursorDisplay(currentSlide !== 8)
  }, [currentSlide])

  useEffect(() => {
    if (currentSlide !== 0 && currentSlide !== 8) {
      const slideTextIndex = slides.findIndex(
        ({ slideNumber }) => slideNumber === currentSlide
      )

      if (slideTextIndex !== -1) {
        setSlideText(slides[slideTextIndex].text)
      } else {
        setSlideText('')
      }
    } else {
      setSlideText('')
    }
  }, [slides, currentSlide])

  const dispatch = useDispatch()

  const handleSelect = (id: string) => {
    dispatch(activateSlide(id))
    setIsModalOpened(true)
    setIsSitemapOpened(false)
    setIsModelDisplay(false)
  }

  const handleOpenSiteMap = () => {
    setIsSitemapOpened(true)
    setIsModelDisplay(false)
  }

  const handleCloseSiteMap = () => {
    setIsSitemapOpened(false)
    setIsModelDisplay(false)
  }

  const handleCloseModal = () => {
    setIsModalOpened(false)
  }

  const handleAssetsProgress = (progress: number) => {
    setAssetsLoadingProgress(progress)
  }

  const handleAssetsLoaded = () => {
    setTimeout(() => {
      setIsAssetsLoading(false)
    }, 300)
  }

  const handleOpenModel = () => {
    setIsModelDisplay(true)
  }

  const handleCloseModel = () => {
    setIsModelDisplay(false)
  }

  return (
    <>
      <AssetsPreloader
        onProgress={handleAssetsProgress}
        onLoaded={handleAssetsLoaded}
      />
      {isAssetsLoading && <Preloader progress={assetsLoadingProgress} />}
      {slideText && <SlideText slide={currentSlide} text={slideText} />}
      <Animation
        ready={!isAssetsLoading}
        active={!isSitemapOpened && !isModelDisplay && !isModalOpened}
        onChangeActiveScreen={setCurrentSlide}
      >
        <Header
          className={classes.header}
          sitemap={isSitemapOpened}
          onOpenSiteMap={handleOpenSiteMap}
          onCloseSiteMap={handleCloseSiteMap}
        />
        {!isModalOpened && !isAudioControlCursorDisplay && (
          <BgAudioControl
            className={classes.player_control}
            isPlaying={isPlaying}
            onClick={() => (isPlaying ? pause() : play())}
          />
        )}
        <Menu
          internalRoomsSlides={internalRoomsSlides}
          externalRoomsSlides={externalRoomsSlides}
          onSelect={handleSelect}
        />
        {isSitemapOpened && (
          <Sitemap onSelect={handleSelect} onOpenModel={handleOpenModel} />
        )}
        {isModalOpened && <SlideModal onClose={handleCloseModal} />}
        <PlatformModal
          visible={isModelDisplay}
          onSelect={handleSelect}
          onClose={handleCloseModel}
        />
      </Animation>
    </>
  )
}

export default Children
