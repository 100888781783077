import { TouchEvent, useEffect, useRef } from 'react'
import { Scrollbars } from 'react-custom-scrollbars-2'

const useAutoScrolling = (enable = true) => {
  const scrollbarsRef = useRef<Scrollbars>(null)
  const isAutoScrolling = useRef(enable)

  useEffect(() => {
    if (!enable) {
      return
    }

    const startVerticalScrolling = () => {
      isAutoScrolling.current = true

      let startTimestamp: number = null

      const verticalScroll = (timestamp: number) => {
        if (!isAutoScrolling.current || !scrollbarsRef.current) {
          return
        }

        if (startTimestamp === null) {
          startTimestamp = timestamp
        }

        if (timestamp - startTimestamp < 40) {
          requestAnimationFrame(verticalScroll)
          return
        }

        const isContentScrolled =
          scrollbarsRef.current.getScrollHeight() -
            scrollbarsRef.current.getClientHeight() <=
          scrollbarsRef.current.getScrollTop()

        if (isContentScrolled) {
          setTimeout(() => {
            if (!isAutoScrolling.current || !scrollbarsRef.current) {
              return
            }

            scrollbarsRef.current.scrollToTop()
            requestAnimationFrame(verticalScroll)
          }, 3000)
          return
        }

        scrollbarsRef.current.scrollTop(
          scrollbarsRef.current.getScrollTop() + 2
        )

        startTimestamp = timestamp
        requestAnimationFrame(verticalScroll)
      }

      requestAnimationFrame(verticalScroll)
    }

    const stopVerticalScrolling = () => {
      isAutoScrolling.current = false
    }

    const { container } = scrollbarsRef.current

    const toggleVerticalScrolling = (e: TouchEvent) => {
      if (container.contains(e.target as Node)) {
        stopVerticalScrolling()
      } else {
        startVerticalScrolling()
      }
    }

    container.addEventListener('mouseover', stopVerticalScrolling)
    container.addEventListener('mouseleave', startVerticalScrolling)
    // @ts-expect-error bad typing
    container.addEventListener('touchstart', toggleVerticalScrolling)

    startVerticalScrolling()

    return () => {
      container.removeEventListener('mouseover', stopVerticalScrolling)
      container.removeEventListener('mouseleave', startVerticalScrolling)
      // @ts-expect-error bad typing
      container.removeEventListener('touchstart', toggleVerticalScrolling)
    }
  }, [enable])

  return {
    scrollbarsRef,
  }
}

export default useAutoScrolling
