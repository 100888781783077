import { ThunkDispatch } from 'redux-thunk'
import * as logoApi from 'api/logo'
import {
  LOAD_LOGOS_BEGIN,
  LOAD_LOGOS_SUCCESS,
  LOAD_LOGOS_FAIL,
} from './constants'
import { Logo } from './model'

const loadLogos = () => async (dispatch: ThunkDispatch<{}, {}, Action>) => {
  dispatch(loadLogosBegin())

  try {
    const logos = await logoApi.loadLogos()

    dispatch(loadLogosSuccess(logos))
  } catch (error) {
    dispatch(loadLogosFail(error as Error))
  }
}

const loadLogosBegin = () =>
  ({
    type: LOAD_LOGOS_BEGIN,
  } as const)

const loadLogosSuccess = (data: Array<Logo>) =>
  ({
    type: LOAD_LOGOS_SUCCESS,
    payload: {
      data,
    },
  } as const)

const loadLogosFail = (error: Error) =>
  ({
    type: LOAD_LOGOS_FAIL,
    payload: {
      error,
    },
  } as const)

type LoadLogosBegin = ReturnType<typeof loadLogosBegin>
type LoadLogosSuccess = ReturnType<typeof loadLogosSuccess>
type LoadLogosFail = ReturnType<typeof loadLogosFail>

type Action = LoadLogosBegin | LoadLogosSuccess | LoadLogosFail

export { loadLogos }
export type { Action }
