import { forwardRef, ForwardRefRenderFunction, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { sortedInternalRoomsSlidesSelector } from 'app/redux/slider/selectors'
import useFindParentSlide from 'app/hooks/useFindParentSlide'
import Platform from 'app/components/Platform'
import classes from './Contents.module.scss'

const DISPLAYED_ITEMS_COUNT = 6

type ContentsProps = {
  openModal: (e: React.MouseEvent, id: string, title: string) => void
}

const Contents: ForwardRefRenderFunction<HTMLDivElement, ContentsProps> = ({ openModal }, ref) => {
  const internalRoomsSlides = useSelector(sortedInternalRoomsSlidesSelector)

  const [showContent, setShowContent] = useState(false)

  useEffect(() => {
    if (showContent) {
      return
    }

    const handleScroll = () => {
      setShowContent(window.pageYOffset >= window.innerHeight * 0.95)
    }

    window.addEventListener('scroll', handleScroll, { passive: true })

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [showContent])

  const { findParentSlide } = useFindParentSlide()

  const handleSelect = (ids: Array<string>) => {
    const nextSlide = findParentSlide(ids)

    if (!nextSlide) {
      return
    }

    openModal({} as React.MouseEvent, nextSlide.id, nextSlide.title)
  }

  return (
    <section ref={ref} className={classes.contents}>
      <div className={classes.container}>
        {/* <div className={classes.subtitle}>
          Виртуальный тур <br /> по платформе
        </div> */}
        <div className={classes.contents_page}>
          {showContent && (
            <div className={classes.contents_list}>
              <ul className={classes.list_items}>
                {internalRoomsSlides
                  .slice(0, DISPLAYED_ITEMS_COUNT / 2)
                  .map(({ id, title }) => (
                    <li
                      key={id}
                      className={classes.list_item}
                      onClick={e => openModal(e, id, title)}
                    >
                      {title}
                    </li>
                  ))}
              </ul>
              <div className={classes.platform_container}>
                <Platform onSelect={handleSelect} />
              </div>
              <ul className={classes.list_items}>
                {internalRoomsSlides
                  .slice(DISPLAYED_ITEMS_COUNT / 2, DISPLAYED_ITEMS_COUNT)
                  .map(({ id, title }) => (
                    <li
                      key={id}
                      className={classes.list_item}
                      onClick={e => openModal(e, id, title)}
                    >
                      {title}
                    </li>
                  ))}
              </ul>
            </div>
          )}
        </div>
      </div>
    </section>
  )
}

export default forwardRef(Contents)
