import { Action } from './actions'
import {
  LOAD_AUDIOS_BEGIN,
  LOAD_AUDIOS_SUCCESS,
  LOAD_AUDIOS_FAIL,
} from './constants'
import { AudiosState } from './model'

const initialState: AudiosState = {
  isLoading: false,
  error: null,
  data: [],
  types: {}
}

const audioReducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case LOAD_AUDIOS_BEGIN:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case LOAD_AUDIOS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
        types: action.payload.types,
      }
    case LOAD_AUDIOS_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      }
    default:
      return state
  }
}

export default audioReducer
