import { FC } from 'react'
import cn from 'classnames'
import { ReactComponent as SoundOnIcon } from 'icons/sound-on.svg'
import { ReactComponent as SoundOffIcon } from 'icons/sound-off.svg'
import classes from './BgAudioControl.module.scss'

type BgAudioControlProps = {
  className?: string
  isPlaying: boolean
  onClick: () => void
}

const BgAudioControl: FC<BgAudioControlProps> = ({
  className,
  isPlaying,
  onClick,
}) => {
  return (
    <button className={cn(className, classes.play_control)} onClick={onClick}>
      {isPlaying ? <SoundOffIcon /> : <SoundOnIcon />}
    </button>
  )
}

export default BgAudioControl
