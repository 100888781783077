import { ThunkDispatch } from 'redux-thunk'
import * as animationApi from 'api/animation'
import {
  LOAD_ANIMATION_SLIDES_TEXT_BEGIN,
  LOAD_ANIMATION_SLIDES_TEXT_SUCCESS,
  LOAD_ANIMATION_SLIDES_TEXT_FAIL,
} from './constants'
import { Slide } from './model'

const loadAnimationSlidesText =
  () => async (dispatch: ThunkDispatch<{}, {}, Action>) => {
    dispatch(loadAnimationSlidesTextBegin())

    try {
      const slidesText = await animationApi.loadAnimationSlidesText()

      dispatch(
        loadAnimationSlidesTextSuccess(
          slidesText.map(({ slide_number, text, audio }) => ({
            slideNumber: slide_number,
            text,
            audio,
          }))
        )
      )
    } catch (error) {
      dispatch(loadAnimationSlidesTextFail(error as Error))
    }
  }

const loadAnimationSlidesTextBegin = () =>
  ({
    type: LOAD_ANIMATION_SLIDES_TEXT_BEGIN,
  } as const)

const loadAnimationSlidesTextSuccess = (slides: Array<Slide>) =>
  ({
    type: LOAD_ANIMATION_SLIDES_TEXT_SUCCESS,
    payload: {
      slides,
    },
  } as const)

const loadAnimationSlidesTextFail = (error: Error) =>
  ({
    type: LOAD_ANIMATION_SLIDES_TEXT_FAIL,
    payload: {
      error,
    },
  } as const)

type LoadAudiosBegin = ReturnType<typeof loadAnimationSlidesTextBegin>
type LoadAudiosSuccess = ReturnType<typeof loadAnimationSlidesTextSuccess>
type LoadAudiosFail = ReturnType<typeof loadAnimationSlidesTextFail>

type Action = LoadAudiosBegin | LoadAudiosSuccess | LoadAudiosFail

export { loadAnimationSlidesText }
export type { Action }
