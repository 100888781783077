import { FC } from 'react'
import cn from 'classnames'
import { SliderItem } from 'app/redux/slider/model'
import { ReactComponent as DownloadIcon } from '../assets/download.svg'
import classes from './Menu.module.scss'

type MenuProps = {
  className?: string
  internalRoomsSlides: Array<SliderItem>
  externalRoomsSlides: Array<SliderItem>
  onSelect: (id: string) => void
}

const Menu: FC<MenuProps> = ({
  className,
  internalRoomsSlides,
  externalRoomsSlides,
  onSelect,
}) => {
  return (
    <div className={cn(className, classes.content)}>
      <div className={classes.navigation}>
        <div className={classes.navigation__content_wrapper}>
          <div
            className={classes.navigation__content}
            style={{ transition: `transform ${internalRoomsSlides.length * 2}s` }}
          >
            {internalRoomsSlides.map(({ id, title }) => (
              <li
                key={id}
                className={cn(classes.navigation__item, classes.left)}
              >
                <button
                  className={classes.navigation__button}
                  onClick={() => onSelect(id)}
                >
                  {title}
                </button>
              </li>
            ))}
          </div>
        </div>
        <div>
          <div className={classes.navigation__content_wrapper}>
            <div
              className={classes.navigation__content}
              style={{ transition: `transform ${externalRoomsSlides.length * 2}s` }}
            >
              {externalRoomsSlides.map(({ id, title }) => (
                <li
                  key={id}
                  className={cn(classes.navigation__item, classes.right)}
                >
                  <button
                    className={classes.navigation__button}
                    onClick={() => onSelect(id)}
                  >
                    {title}
                  </button>
                </li>
              ))}
            </div>
          </div>
          <a
            className={classes.download_comics}
            href='/static/comics-for-kids.pdf'
            download='Комикс Как добывают нефть в Арктике для детей 7+.pdf'
            target='_blank'
            rel='noreferrer'
          >
            <DownloadIcon />
            <span>Скачать комикс</span>
          </a>
        </div>
      </div>
    </div>
  )
}

export default Menu
