import { SliderItem } from '../model'

const getNextId = (data: Array<SliderItem>) => {
  let currentIndex = data.findIndex(({ active }) => active)

  if (currentIndex === -1) {
    return data[0].id
  }

  const nextIndex = currentIndex + 1

  if (nextIndex >= data.length) {
    return data[0].id
  }

  return data[nextIndex].id
}

export default getNextId
