import { FC, useCallback, useRef } from 'react'
import ImagePreloader from 'app/components/ImagePreloader/ImagePreloader'
// # Children
// ## horizontal-flow
import HorizontalFlowLayer1 from './Animation/assets/images/horizontal-flow/layer-1.png'
import HorizontalFlowLayer2 from './Animation/assets/images/horizontal-flow/layer-2.png'
import HorizontalFlowLayer3 from './Animation/assets/images/horizontal-flow/layer-3.png'
import HorizontalFlowLayerMiddle from './Animation/assets/images/horizontal-flow/layer-middle.png'
// ## main-letters
import MainLettersLayer1 from './Animation/assets/images/main-letters/layer-1.png'
import MainLettersLayer2 from './Animation/assets/images/main-letters/layer-2.png'
import MainLettersLayer2Blur from './Animation/assets/images/main-letters/layer-2-blur.png'
import MainLettersLayer3 from './Animation/assets/images/main-letters/layer-3.png'
import MainLettersLayer4 from './Animation/assets/images/main-letters/layer-4.png'
import MainLettersLayer4Blur from './Animation/assets/images/main-letters/layer-4-blur.png'
// ## platform-menu
import PlatformMenuLayer1 from './Animation/assets/images/platform-menu/layer-1.png'
import PlatformMenuLayer2 from './Animation/assets/images/platform-menu/layer-2.png'
import PlatformMenuLayer3 from './Animation/assets/images/platform-menu/layer-3.png'
import PlatformMenuLayer4 from './Animation/assets/images/platform-menu/layer-4.png'
import PlatformMenuPlatformLayer from './Animation/assets/images/platform-menu/platform-layer.png'
// ## time-laps
import TimeLapsBirdsLayer1 from './Animation/assets/images/time-laps/birds-from-top/layer-1.png'
import TimeLapsBirdsLayer2 from './Animation/assets/images/time-laps/birds-from-top/layer-2.png'
import TimeLapsBirdsLayer3 from './Animation/assets/images/time-laps/birds-from-top/layer-3.png'
import TimeLapsPlatformLayer1 from './Animation/assets/images/time-laps/platform-view/layer-1.png'
import TimeLapsPlatformLayer2 from './Animation/assets/images/time-laps/platform-view/layer-2.png'
import TimeLapsPlatformLayer3 from './Animation/assets/images/time-laps/platform-view/layer-3.png'
import TimeLapsZoomedLayer1 from './Animation/assets/images/time-laps/zoomed-birds/layer-1.png'
import TimeLapsZoomedLayer2 from './Animation/assets/images/time-laps/zoomed-birds/layer-2.png'
// ## SlideModal
import SlideModalBg from 'images/children_modal_bg.png'

const assets = [
  HorizontalFlowLayer1,
  HorizontalFlowLayer2,
  HorizontalFlowLayer3,
  HorizontalFlowLayerMiddle,
  MainLettersLayer1,
  MainLettersLayer2,
  MainLettersLayer2Blur,
  MainLettersLayer3,
  MainLettersLayer4,
  MainLettersLayer4Blur,
  PlatformMenuLayer1,
  PlatformMenuLayer2,
  PlatformMenuLayer3,
  PlatformMenuLayer4,
  PlatformMenuPlatformLayer,
  TimeLapsBirdsLayer1,
  TimeLapsBirdsLayer2,
  TimeLapsBirdsLayer3,
  TimeLapsPlatformLayer1,
  TimeLapsPlatformLayer2,
  TimeLapsPlatformLayer3,
  TimeLapsZoomedLayer1,
  TimeLapsZoomedLayer2,
  SlideModalBg,
] as const

type AssetsPreloaderProps = {
  onProgress?: (progress: number) => void
  onLoaded?: () => void
}

const AssetsPreloader: FC<AssetsPreloaderProps> = ({ onProgress, onLoaded }) => {
  const counterRef = useRef(0)

  const handleLoaded = useCallback(() => {
    counterRef.current++

    onProgress && onProgress(counterRef.current / assets.length * 100)

    if (counterRef.current === assets.length) {
      onLoaded && onLoaded()
    }
  }, [])

  return (
    <div
      style={{
        display: 'none',
        position: 'absolute',
        zIndex: -1000,
        top: -1000,
        left: -1000,
      }}
    >
      {assets.map(src => (
        <ImagePreloader key={src} src={src} onLoaded={handleLoaded} />
      ))}
    </div>
  )
}

export default AssetsPreloader
