import React, {
  useEffect,
  useState,
  FC,
  useCallback,
  useRef,
} from 'react'
import cn from 'classnames'
import { Header } from './header/Header'
import { Title } from './title/Title'
import Contents from './contents/Contents'
import Slides from './slides/Slides'
import { Sitemap } from './sitemap/Sitemap'
import classes from './Adult.module.scss'
import { useDispatch } from 'react-redux'
import { activateSlide } from 'app/redux/slider/actions'
import Preloader from './preloader/Preloader'
import { ReactComponent as SoundOnLogo } from 'images/sound_on.svg'
import { ReactComponent as SoundOffLogo } from 'images/sound_off.svg'
import useHideScrollbar from 'app/hooks/useHideScrollbar'
import useBackgroundAudio from './hooks/useBackgroundAudio'
import useKeyDown from 'app/hooks/useKeyDown'
import useEffectWhen from 'app/hooks/useEffectWhen'
import useResetWindowScroll from 'app/hooks/useResetWindowScroll'

const PRELOADER_ANIMATION_DURATION = 6_000
const CURTAIN_ANIMATION_DURATION = 1_000

const seen = Boolean(localStorage.getItem('adult/preloader/seen'))

const Adult: FC = () => {
  const [modal, setModal] = useState(false)
  const [isScrollbarHidden, setIsScrollbarHidden] = useState(false)
  const [sitemap, setSitemap] = useState(false)
  const [changeHeader, setChangeHeader] = useState(false)
  const [curtainTitle, setCurtainTitle] = useState('')
  const [isCurtainTitleDisplay, setIsCurtainTitleDisplay] = useState(false)
  const [isTitlePage, setIsTitlePage] = useState(true)
  const [needPlayBgAudio, setNeedPlayBgAudio] = useState(false)
  const [isPreloaderDisplayed, setIsPreloaderDisplayed] = useState(!seen)

  useBackgroundAudio(!modal && needPlayBgAudio)

  useHideScrollbar(document.body, isScrollbarHidden)
  useResetWindowScroll()

  let circleAnimation = null
  let curtainText = null

  useEffect(() => {
    localStorage.setItem('adult/preloader/seen', 'true')

    if (isPreloaderDisplayed) {
      setTimeout(() => {
        setIsPreloaderDisplayed(false)
      }, PRELOADER_ANIMATION_DURATION)
    }
  }, [])

  const dispatch = useDispatch()

  const animateFromMousePosition = (e: React.MouseEvent) => {
    circleAnimation = document.getElementById('circleAnimation')

    if (!circleAnimation) {
      return
    }

    let x = e.clientX
    let y = e.clientY
    circleAnimation.style.top = y + 20 + 'px'
    circleAnimation.style.left = x + 20 + 'px'
    circleAnimation.style.display = 'block'
    setTimeout(() => {
      circleAnimation.style.display = 'none'
    }, CURTAIN_ANIMATION_DURATION) // менять показатель в зависимости от продолжительности анимации в css
  }

  const curtainUp = () => {
    curtainText = document.getElementById('curtainText')
    curtainText.style.transform = 'translate(0, -100%)'

    setTimeout(() => {
      setIsCurtainTitleDisplay(false)
    }, CURTAIN_ANIMATION_DURATION)
  }

  useKeyDown('Space', curtainUp)

  useEffectWhen(() => {
    const timeoutId = setTimeout(curtainUp, CURTAIN_ANIMATION_DURATION)

    return () => {
      clearTimeout(timeoutId)
    }
  }, modal)

  const handleOpenModal = (
    e: React.MouseEvent,
    id: string,
    title: string
  ): void => {
    setCurtainTitle(title)
    setIsCurtainTitleDisplay(true)
    animateFromMousePosition(e)
    setIsScrollbarHidden(true)
    dispatch(activateSlide(id))
    setChangeHeader(true)
    setTimeout(() => {
      setModal(true)
      curtainText = document.getElementById('curtainText')

      if (!curtainText) {
        return
      }

      curtainText.style.display = 'flex'
      curtainText.style.justifyContent = 'center'
      curtainText.style.alignItems = 'center'
    }, CURTAIN_ANIMATION_DURATION)
  }
  const handleCloseModal = () => {
    setChangeHeader(false)
    setModal(false)
    setIsScrollbarHidden(false)
  }

  const handleOpenSitemap = () => {
    setIsScrollbarHidden(true)
    setSitemap(prev => !prev)
  }
  const handleCloseSitemap = () => {
    !modal && setIsScrollbarHidden(false)
    setSitemap(prev => !prev)
  }

  const contentsRef = useRef<HTMLDivElement>(null)

  const handleCloseTitle = useCallback(() => {
    if (contentsRef.current === null) {
      return
    }

    contentsRef.current.scrollIntoView({ behavior: 'smooth' })
    setIsTitlePage(false)
  }, [])

  const handleSelect = (id: string) => {
    dispatch(activateSlide(id))
    setModal(true)
    setSitemap(false)
    setChangeHeader(true)
  }

  return (
    <div>
      {/* {isPreloaderDisplayed && (
        <div className={classes.preloader}>
          <embed
            type='image/svg+xml'
            src={Preloader}
            style={{ width: '130%', height: '130%' }}
          />
        </div>
      )} */}
      {isPreloaderDisplayed && (
        <Preloader duration={PRELOADER_ANIMATION_DURATION} />
      )}
      {sitemap && <Sitemap onSelect={handleSelect} />}
      <div id='circleAnimation' className={classes.curtain_modal}></div>
      {modal && (
        <>
          <div className={classes.text_relative}>
            <div id='curtainText' className={classes.curtain_background}>
              {/* <div className={classes.curtain_subtitle}>
                Виртуальный тур <br /> по платформе
              </div> */}
              <div className={classes.curtain_text}>{curtainTitle}</div>
            </div>
          </div>
          <Slides closeModal={handleCloseModal} />
        </>
      )}
      <div>
        {changeHeader ||
          (!modal && (
            <button
              className={cn(classes.bd_audio_control, {
                [classes.right]: !isTitlePage,
              })}
              onClick={() => setNeedPlayBgAudio(need => !need)}
            >
              {needPlayBgAudio ? <SoundOffLogo /> : <SoundOnLogo style={{ paddingRight: '2px' }} />}
            </button>
          ))}
        <Header
          displayLogo={!isCurtainTitleDisplay}
          modal={changeHeader}
          sitemap={sitemap}
          openSitemap={handleOpenSitemap}
          closeSitemap={handleCloseSitemap}
        />
        <div className={classes.gray_bg}>
          <Title onClose={handleCloseTitle} />
          <Contents ref={contentsRef} openModal={handleOpenModal} />
        </div>
      </div>
    </div>
  )
}

export default Adult
