import { setMouseParallax, setScrollParallax } from '../../utils/functions'
import gsap from 'gsap'

export function mainLetters(mouse, scroll, swipeDuration) {
  const main = document.querySelector('#main')
  const elementToWatch = document.querySelector('#mainLetters')
  const elementBackgroundWrapper = document.querySelector('#mainBackground')

  let elementBackgroundHeight = 0,
    elementToWatchHeight = 0

  const elementBackroundLayers = document.querySelectorAll(
    '.main-background__layers .layer'
  )

  let timelines = []
  Array.from(elementBackroundLayers).forEach(() => {
    timelines.push(gsap.timeline())
  })

  if (
    elementBackgroundHeight !==
      elementBackgroundWrapper.getBoundingClientRect().height ||
    elementToWatchHeight !== elementToWatch.getBoundingClientRect().height
  ) {
    elementBackgroundHeight =
      elementBackgroundWrapper.getBoundingClientRect().height
    elementToWatchHeight = elementToWatch.getBoundingClientRect().height
    main.style.height = `${elementBackgroundHeight + elementToWatchHeight}px`
  }

  Array.from(elementBackroundLayers).forEach((layer, idx) => {
    if (!scroll.scrolling) setMouseParallax(layer, mouse, 1 + idx)
    setScrollParallax(
      layer,
      scroll,
      timelines[idx],
      idx * 10 + idx * 2,
      swipeDuration
    )
  })
}
