import { useEffect, useState } from 'react'

const useDocumentReadyState = () => {
  const [isReady, setIsReady] = useState(document.readyState === 'complete')

  useEffect(() => {
    const intervalId = setInterval(() => {
      const isReady = document.readyState === 'complete'

      if (!isReady) {
        return
      }

      setIsReady(true)
      clearInterval(intervalId)
    }, 100)

    return () => {
      clearInterval(intervalId)
    }
  }, [])

  return {
    isDocumentReady: isReady,
  }
}

export default useDocumentReadyState
