type Params = {
  iteration: number
  timing: number
  start?: () => void
  progress?: () => void
  finish?: () => void
}

const doSmooth = ({ iteration, timing, start, progress, finish }: Params) => {
  start && start()

  let i = 0

  const intervalId = setInterval(() => {
    progress && progress()

    i++

    if (i >= iteration) {
      finish && finish()
      clearInterval(intervalId)
    }
  }, timing)
}

export default doSmooth
