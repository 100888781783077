import { FC, useEffect, useRef } from 'react'
import { ReactComponent as PreloaderIcon } from './assets/preloader.svg'
import './Preloader.scss'

type PreloaderProps = {
  progress: number
}

const Preloader: FC<PreloaderProps> = ({ progress }) => {
  const preloaderBg = useRef<HTMLSpanElement>(null)

  useEffect(() => {
    preloaderBg.current.style.transform = `translateY(${-progress}%)`
  }, [progress])

  return (
    <div className='preloader'>
      <div className='preloader__wrapp'>
        <span ref={preloaderBg} className='preloader_bg'></span>
        <PreloaderIcon />
      </div>
    </div>
  )
}

export default Preloader
