import { FC, useState, useEffect } from 'react'
import toMMSS from 'lib/toMMSS'
import useAudioGuide from 'app/hooks/useAudioGuide'
import { ReactComponent as AudioWaveIcon } from 'icons/audio-wave.svg'
import classes from './AudioGuide.module.scss'

const options = {
  progressColor: '#F08300',
} as const

type AudioGuideProps = {
  mode: 'colored' | 'white'
  src: string
  title: string
}

const AudioGuide: FC<AudioGuideProps> = ({ mode, src, title }) => {
  const {
    waveformContainerRef,
    isPlaying,
    isLoading,
    duration,
    progress,
    handlePlay,
    handlePause,
  } = useAudioGuide(src, options)

  const [scaling, setScaling] = useState(0)

  useEffect(() => {
    setScaling(window.screen.width / 1920)
  }, [window.screen.width])

  return (
    <div className={classes.guide}>
      <div className={classes.guide_controlls} style={{ transform: `scale(${scaling})` }}>
        <div ref={waveformContainerRef} className={classes.guide_timeline}>
          {isLoading && (
            <div className={classes.guide_timeline_loader}>
              <AudioWaveIcon
                style={{ color: mode === 'white' ? '#fff' : '#F08300' }}
              />
            </div>
          )}
        </div>
        <button
          className={isPlaying ? classes.stop_btn : classes.play_btn}
          disabled={isLoading}
          onClick={isPlaying ? handlePause : handlePlay}
        />
        <div
          className={classes.info_main}
          style={
            mode === 'white' ? { color: '#ffffff' } : { color: '#000000' }
          }
        >
          <div className={classes.player_title}>
            Аудиогид
          </div>
          <div className={classes.description_group}>
            <div>{title}</div>
            {!isLoading && (
              <div className={classes.info_time}>
                {toMMSS(duration - progress)}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default AudioGuide
