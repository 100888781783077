import { FC } from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import cn from 'classnames'
import routes from 'app/routes'
import { sortedSlidesSelector } from 'app/redux/slider/selectors'
import { ReactComponent as DownloadIcon } from '../assets/download.svg'
import classes from './Sitemap.module.scss'

type SitemapProps = {
  onSelect: (id: string) => void
  onOpenModel: () => void
}

export const Sitemap: FC<SitemapProps> = ({ onSelect, onOpenModel }) => {
  const slides = useSelector(sortedSlidesSelector)

  const handleReload = () => {
    window.location.reload()
  }

  // Костыль, помогает сбросить scrollTop у window
  const handleDeferReload = () => {
    setTimeout(() => {
      window.location.reload()
    }, 0)
  }

  return (
    <div className={classes.sitemap_page}>
      <div className={classes.sitemap_content}>
        <h2 className={classes.sitemap_title}>Карта сайта</h2>
        <div className={classes.slides}>
          {slides.map(({ id, title }) => (
            <button
              key={id}
              className={classes.slide_item}
              onClick={e => {
                e.stopPropagation()
                onSelect(id)
              }}
            >
              {title}
            </button>
          ))}
          <div className={cn(classes.nav)}>
            <a
              className={cn(classes.nav_item, classes.download_comics)}
              href='/static/comics-for-kids.pdf'
              download='Комикс Как добывают нефть в Арктике для детей 7+.pdf'
              target='_blank'
              rel='noreferrer'
            >
              <DownloadIcon />
              Скачать комикс!
            </a>
            <button
              className={classes.nav_item}
              onClick={e => {
                e.stopPropagation()
                onOpenModel()
              }}
            >
              Разглядеть модель
            </button>
            <Link
              className={classes.nav_item}
              to={routes.kids}
              onClick={handleReload}
            >
              Вернуться на главную
            </Link>
            <Link
              className={classes.nav_item}
              to={routes.main}
              onClick={handleDeferReload}
            >
              Взглянуть иначе
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}
