import { Action } from './actions'
import {
  LOAD_LOGOS_BEGIN,
  LOAD_LOGOS_SUCCESS,
  LOAD_LOGOS_FAIL,
} from './constants'
import { AudiosState } from './model'

const initialState: AudiosState = {
  isLoading: false,
  error: null,
  data: [],
}

const logoReducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case LOAD_LOGOS_BEGIN:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case LOAD_LOGOS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
      }
    case LOAD_LOGOS_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      }
    default:
      return state
  }
}

export default logoReducer
