import doSmooth from 'lib/doSmooth'
import { useEffect, useRef, useState } from 'react'
import useDocumentReadyState from './useDocumentReadyState'

type Options = {
  loop?: boolean
  autoPlay?: boolean
  volume?: number
}

const useAudio = (src: string, options: Options) => {
  const { loop = false, autoPlay = false, volume = 1 } = options

  const playerRef = useRef<HTMLAudioElement>(null)
  const [isPlaying, setIsPlaying] = useState(false)
  const [readyToPlay, setReadyToPlay] = useState(false)

  useEffect(() => {
    const needPlay = isPlaying

    if (isPlaying) {
      stop()
    }

    playerRef.current = new Audio(src)

    const handleCanPlay = () => {
      setReadyToPlay(true)

      if (needPlay) {
        play()
      }
    }

    playerRef.current.addEventListener('canplaythrough', handleCanPlay)

    return () => {
      playerRef.current.removeEventListener('canplaythrough', handleCanPlay)
    }
  }, [src])

  useEffect(() => {
    playerRef.current.volume = volume
  }, [volume])

  useEffect(() => {
    if (!playerRef.current || !loop) {
      return
    }

    playerRef.current.addEventListener('ended', replay)

    return () => {
      playerRef.current.removeEventListener('ended', replay)
    }
  }, [loop])

  const { isDocumentReady } = useDocumentReadyState()

  useEffect(() => {
    if (!isDocumentReady || !autoPlay) {
      return
    }

    play()

    return () => {
      stop()
    }
  }, [isDocumentReady, autoPlay])

  useEffect(() => {
    return () => {
      stop()
    }
  }, [])

  const resetVolume = () => {
    playerRef.current.volume = 0
  }

  const setDefaultVolume = () => {
    playerRef.current.volume = volume
  }

  const increaseVolume = () => {
    let nextVolume = playerRef.current.volume + volume / 5

    if (nextVolume > volume) {
      playerRef.current.volume = volume
      return
    }

    playerRef.current.volume = nextVolume
  }

  const decreaseVolume = () => {
    let nextVolume = playerRef.current.volume - volume / 5

    if (nextVolume < 0) {
      return
    }

    playerRef.current.volume = nextVolume
  }

  const play = () => {
    if (!playerRef.current || !readyToPlay) {
      return
    }

    playerRef.current.play()
    setIsPlaying(true)
  }

  const replay = () => {
    if (!playerRef.current) {
      return
    }

    playerRef.current.currentTime = 0
    playerRef.current.play()
    setIsPlaying(true)
  }

  const pause = () => {
    if (!playerRef.current) {
      return
    }

    playerRef.current.pause()
    setIsPlaying(false)
  }

  const stop = () => {
    if (!playerRef.current) {
      return
    }

    playerRef.current.pause()
    playerRef.current.currentTime = 0
    setIsPlaying(false)
  }

  return {
    isPlaying,
    readyToPlay,
    play: (smooth = false) => {
      if (smooth) {
        doSmooth({
          iteration: 5,
          timing: 100,
          start: resetVolume,
          progress: increaseVolume,
        })
      }

      play()
    },
    replay,
    pause: (smooth = false) => {
      if (smooth) {
        doSmooth({
          iteration: 5,
          timing: 100,
          progress: decreaseVolume,
          finish: () => {
            pause()
            setDefaultVolume()
          },
        })
      } else {
        pause()
      }
    },
    stop: (smooth = false) => {
      if (smooth) {
        doSmooth({
          iteration: 5,
          timing: 100,
          progress: decreaseVolume,
          finish: () => {
            stop()
            setDefaultVolume()
          },
        })
      } else {
        stop()
      }
    },
  }
}

export default useAudio
