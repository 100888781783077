import {Object3D} from "three";

export class Object3DHelper {
    static getIsNameOfObjectOrParent(object: Object3D, name: string): boolean {
        return object.name === name
            || (object.parent instanceof Object3D && object.parent && object.parent.name === name);
    }

    static getIsHasParent(object: Object3D): boolean {
        return object.parent && object.parent instanceof Object3D;
    }
}