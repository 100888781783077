import {
    AmbientLight, AnimationMixer,
    Camera,
    Clock,
    Color,
    DoubleSide,
    EdgesGeometry,
    Group, Intersection,
    LinearEncoding,
    LineBasicMaterial,
    LineSegments, MathUtils,
    Mesh,
    MeshBasicMaterial,
    Object3D,
    OrthographicCamera,
    PerspectiveCamera, Raycaster,
    Scene, Vector2, Vector3,
    WebGLRenderer
} from "three";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass";
import { GLTF, GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { Object3DHelper } from "../helpers/Object3DHelper";
import { SceneService } from '../services/SceneService';

require('fpsmeter');

const videoNumbers = {
    '1': ['116', '118', '119', '222', '225', '230', '234', '235', '237', '248'],
    '2': ['106', '107', '157', '262', '293', '294'],
    '3': ['201', '205', '207', '214', '215', '216'],
    '4': ['151', '152', '153', '154'],
    '5': ['163', '264'],
    '6': ['140', '141', '142', '144', '145', '148'],
    '7': ['276', '286', '289'],
    '8': ['201', '205', '207', '214', '215', '216'],
} as const

export interface MainModelParams {
    width?: number
    height?: number
    fieldOfView?: number
    aspect?: number
    maxControlsDistance?:number
    minControlsDistance?:number
    onSelect?: (ids: string[]) => void,
    addOffsetLeft?:number,
}

export class MainModel {
    container: HTMLElement;
    renderer: WebGLRenderer;
    composer: EffectComposer;
    camera: Camera;
    scene: Scene;
    light: AmbientLight;
    clock: Clock;
    stats: FPSMeter;
    controls: OrbitControls;
    mesh: Group;
    raycaster: Raycaster;
    raycasterPoint: Vector2;
    intersectedObject: Mesh;
    intersectedObjectMaterial: MeshBasicMaterial;
    intersectedObjectActiveMaterial: MeshBasicMaterial;
    mixer: AnimationMixer;
    points: Array<Mesh> = [];
    params: MainModelParams;
    previousMousePos:Vector2;

    /**
     * Метод реализовывает вход в уровень
     */
    async enter(params: MainModelParams): Promise<void> {

        if (window['three_level'] instanceof MainModel) {
            window['three_level'].exit();
        }

        this.params = params;
        await this.beforeInit().then(async () => {
            await this.initScene();
            await this.initRenderer(params.width, params.height);
            this.initClock();
            //this.initStats();
            this.initCamera();
            this.initLights();
            this.initPostprocessing();
            await this.initModel();
            await this.initRaycaster();
            this.initControls();
            this.addEventListeners();
        });

        window['three_level'] = this;

        return await this.afterInit();
    }

    protected async beforeInit() {

    }

    protected async initStats() {

        this.stats = new FPSMeter(
            document.getElementById('stats_container'),
            {
                theme: 'colorful',
                heat: 1,
            }
        );
    }

    protected async initClock() {
        this.clock = new Clock();
    }

    protected async initScene() {

        this.scene = new Scene();
        //this.scene.background = new (Color)(0xf0f0f0);
        //this.scene.fog = new Fog(0xE0FFFF, 100, 500);
    }

    protected async initCamera() {

        this.camera = new PerspectiveCamera(this.params.fieldOfView, this.params.aspect, 1, 4000)
    }

    protected async initRenderer(width = window.innerWidth, height = window.innerHeight) {
        this.renderer = new WebGLRenderer({ antialias: true, alpha: true });
        this.renderer.setPixelRatio(window.devicePixelRatio);
        this.renderer.setSize(width, height);
        this.renderer.setClearColor(0x000000, 0);
        this.container = document.getElementById('gl_container');
        this.container.style.display = 'none';
        this.container.appendChild(this.renderer.domElement);
        this.renderer.shadowMap.enabled = true;
        this.renderer.outputEncoding = LinearEncoding;
        this.renderer.shadowMap.type = 2;
    }

    protected async initPostprocessing() {

        this.renderer.autoClear = false;

        const renderModel = new RenderPass(this.scene, this.camera);

        /*const effectBleach = new ShaderPass(BleachBypassShader);
        const effectColor = new ShaderPass(ColorCorrectionShader);
        let effectFXAA = new ShaderPass(FXAAShader);
        const gammaCorrection = new ShaderPass(GammaCorrectionShader);

        effectFXAA.uniforms['resolution'].value.set(1 / window.innerWidth, 1 / window.innerHeight);

        effectBleach.uniforms['opacity'].value = 0.1;

        effectColor.uniforms['powRGB'].value.set(1.4, 1.45, 1.45);
        effectColor.uniforms['mulRGB'].value.set(1.1, 1.1, 1.1);*/

        this.composer = new EffectComposer(this.renderer);

        this.composer.addPass(renderModel);
        //this.composer.addPass(effectFXAA);
        //this.composer.addPass(effectBleach);
        //this.composer.addPass(effectColor);
        //this.composer.addPass(gammaCorrection);
    }

    protected async initLights() {

        this.light = new AmbientLight(0xffffff, 3);
        this.scene.add(this.light);
    }

    protected async addEventListeners() {
        window.addEventListener('resize', this.onWindowResize.bind(this));
        document.addEventListener('mousemove', this.onPointerMove.bind(this));
        this.container.addEventListener('mouseup', this.onMouseUp.bind(this))
        this.container.addEventListener('mousedown', this.onMouseDown.bind(this))
        document.addEventListener('touchmove', this.onPointerMove.bind(this));
        document.addEventListener('touchstart', this.onPointerMove.bind(this));
    }

    protected async initModel() {
        let loader = new GLTFLoader();
        await loader.loadAsync('models/v9.2_anim.glb')
            .then(
                (gltf: GLTF) => {
                    console.log(gltf);

                    gltf.scene.traverse((object: Object3D) => {
                        if (object instanceof Object3D) {
                            if (Object3DHelper.getIsNameOfObjectOrParent(object, 'flag')) {
                                return;
                            }

                            if (object instanceof Mesh) {
                                this.handlerMesh(object);
                            }

                        }

                    });

                    this.scene.add(gltf.scene);
                    this.mesh = gltf.scene;


                    const animations = gltf.animations;

                    this.mixer = new AnimationMixer(gltf.scene);

                    this.mixer.clipAction(animations[0]).play();
                    this.container.style.display = 'block';


                },
                (reason: any) => {
                    console.error("Rejected: " + reason);
                }
            );
    }

    protected async initRaycaster() {
        this.raycaster = new Raycaster();
        this.raycasterPoint = new Vector2(10000, 10000);
        this.previousMousePos = new Vector2(10000, 10000);
        this.intersectedObjectActiveMaterial = new MeshBasicMaterial({
            color: new Color(0xff0000),
            transparent: true,
            opacity: 1,
            side: 2,
        });
        this.intersectedObjectMaterial = new MeshBasicMaterial({
            color: new Color(0xffffff),
            transparent: true,
            opacity: 1,
            side: 2,
        });
    }

    protected handlerMesh(mesh: Mesh): void {
        let geo;

        if (mesh.name.indexOf('cam_') !== -1) {
            return;
        }

        if (Object3DHelper.getIsNameOfObjectOrParent(mesh, 'kran') || Object3DHelper.getIsNameOfObjectOrParent(mesh, 'tube')) {
            geo = new EdgesGeometry(mesh.geometry, 40);
        } else if (Object3DHelper.getIsNameOfObjectOrParent(mesh, 'ferma')) {
            geo = new EdgesGeometry(mesh.geometry, 179);
        } else {
            geo = new EdgesGeometry(mesh.geometry, 1);
        }

        let mat = new LineBasicMaterial({ color: 0x002855, linewidth: 1 });
        mat.side = DoubleSide;
        mat.transparent = false;
        mat.opacity = 1;

        let wireframe = new LineSegments(geo, mat);

        wireframe.renderOrder = 0;
        mesh.renderOrder = 1;

        if (Object3DHelper.getIsNameOfObjectOrParent(mesh, 'tower')) {
            wireframe.scale.set(1.001, 1.001, 1.001);
            wireframe.position.x -= 0.015;
            wireframe.position.z -= 0.015;
        }

        if (Object3DHelper.getIsNameOfObjectOrParent(mesh, 'p')) {
            wireframe.scale.set(1.001, 1.001, 1.001);
            wireframe.position.x -= 0.015;
            wireframe.position.z -= 0.015;
        }

        if (mesh.name === 'ferma' || mesh.name === 'Mesh005' || mesh.name === 'Mesh005_1') {
            console.log('is ferma');
            mesh.material = new MeshBasicMaterial({
                color: new Color(0x002855),
                transparent: true,
                opacity: 0.6,
                side: 2,
            });
        } else if (mesh.name === 'kran1_ferma' || mesh.name === 'kran2_ferma') {


            mesh.material = new MeshBasicMaterial({
                color: new Color(0x002855),
                transparent: true,
                opacity: 0.6,
                side: 2,
            });


        } else if (mesh.name === 'kran1_base_wire' || mesh.name === 'kran2_base_wire') {


            mesh.material = new MeshBasicMaterial({
                color: new Color(0x002855),
                wireframe: true,
            });
            mesh.renderOrder = 10;


        } else if (mesh.name === 'kran1_base' || mesh.name === 'kran2_base') {

            mesh.material = new MeshBasicMaterial({
                color: new Color(0xffffff),
                transparent: true,
                opacity: 1,
                side: 2,
            });

        } else {
            mesh.material = new MeshBasicMaterial({
                color: new Color(0xffffff),
                side: 2,
            });
            mesh.add(wireframe);
        }

        if (mesh.name.indexOf('_box') === 0) {
            mesh.visible = false;
        }
    }

    protected async initControls() {
        this.controls = new OrbitControls(this.camera, this.renderer.domElement);
        //this.controls.listenToKeyEvents( window ); // optional

        //controls.addEventListener( 'change', render ); // call this only in static scenes (i.e., if there is no animation loop)

        this.controls.enableDamping = true; // an animation loop is required when either damping or auto-rotation are enabled
        this.controls.dampingFactor = 0.025;

        this.controls.screenSpacePanning = false;
        this.controls.enablePan = false;

        this.controls.minDistance = this.params.minControlsDistance;
        this.controls.maxDistance = this.params.maxControlsDistance;

        this.controls.maxPolarAngle = Math.PI / 2;
        this.controls.update();

        this.camera.position.set(-57.993768642680244, 75.13778190094818, -284.5892417671902);
        this.camera.rotation.set(-2.8834610984719182, -0.19453727825198508, -3.090598159431723);
    }

    /**
     * Метод реализовывает выход из уровня
     */
    async exit(): Promise<void> {
        const serv = new SceneService();
        serv.clearScene(this.scene);
    }

    /**
     * Метод будет вызван в самом конце входа в уровень
     */
    protected async afterInit(): Promise<void> {
        this.animate();
    }

    onWindowResize() {
        /*if (this.camera instanceof PerspectiveCamera || this.camera instanceof OrthographicCamera) {
            this.camera['aspect'] = window.innerWidth / window.innerHeight;
            this.camera.updateProjectionMatrix()
        }
        this.renderer.setSize(window.innerWidth, window.innerHeight)*/
    }

    onPointerMove(event) {
        const rect = this.renderer.domElement.getBoundingClientRect();

        this.raycasterPoint.x = ( ( event.clientX - rect.left ) / ( rect.width - rect.left ) ) * 2 - 1;
        this.raycasterPoint.y = - ( ( event.clientY - rect.top ) / ( rect.bottom - rect.top) ) * 2 + 1;

        this.intersect();
    }

    onMouseUp(event) {
        const rect = this.renderer.domElement.getBoundingClientRect();

        this.raycasterPoint.x = ( ( event.clientX - rect.left ) / ( rect.width - rect.left ) ) * 2 - 1;
        this.raycasterPoint.y = - ( ( event.clientY - rect.top ) / ( rect.bottom - rect.top) ) * 2 + 1;

        if (this.previousMousePos.distanceToSquared(this.raycasterPoint) === 0) {
            this.intersect(true);
        }
    }

    onMouseDown(event) {
        const rect = this.renderer.domElement.getBoundingClientRect();

        this.previousMousePos.x = ( ( event.clientX - rect.left ) / ( rect.width - rect.left ) ) * 2 - 1;
        this.previousMousePos.y = - ( ( event.clientY - rect.top ) / ( rect.bottom - rect.top) ) * 2 + 1;
    }

    animate(): void {
        //this.stats.tickStart();

        requestAnimationFrame(() => {
            this.animate()
        });
        this.controls.update();

        let delta = this.clock.getDelta();
        this.mixer.update(delta);

        this.points.forEach((point: Mesh) => {
            point.lookAt(this.camera.position);
        });

        const radAngle: number = this.controls.getAzimuthalAngle();
        const degAngle: number = MathUtils.radToDeg(radAngle) + 100;
        const element = document.getElementById('rotateCompas');
        if (element) {
            element.style.transform = 'rotate(' + degAngle + 'deg)';
        }

        this.render();
        this.glstats();
        //this.stats.tick();
    }

    intersect(click: boolean = false): void {

        if (this.intersectedObject) {
            this.uncolor(this.intersectedObject);
            this.intersectedObject = null;
        }

        this.raycaster.setFromCamera(this.raycasterPoint, this.camera);
        const intersects = this.raycaster.intersectObjects(this.scene.children, true);

        let isReturn = false;
        intersects.forEach((value: Intersection<Object3D<Event>>, index: number) => {
            if (isReturn) {
                return;
            }
            if (value.object instanceof Mesh && value.object.name.indexOf('_') === 0) {

                if (value.object) {
                    let number = value.object.name.replace('_', '').replace('box_', '');
                    this.scene.traverse((object: Object3D<Event>) => {
                        if (object instanceof Mesh && object.name.indexOf('_box_') === -1 && object.name.indexOf('_') === 0) {
                            const object_number = object.name.replace('_', '');
                            if (object_number === number) {
                                this.color(object);
                                this.intersectedObject = object;
                                if (click) {
                                    this.playVideo(object);
                                }
                                isReturn = true;
                            } else {
                                this.uncolor(object);
                            }
                        }
                    });
                }

            }
        });
    }

    playVideo(object: Mesh): void {
        const number = object.name.replace('_', '');
        this.params.onSelect && this.params.onSelect(videoNumbers[number])
    }

    color(mesh: Mesh): void {
        mesh.traverse((object: Object3D) => {
            if (object instanceof LineSegments) {
                //object.material = new LineBasicMaterial({color: 0xED8B00, linewidth: 1});
            }
        });
        mesh.material = new MeshBasicMaterial({ color: 0xED8B00, transparent: true, opacity: 0.9 });
        this.renderer.domElement.style.cursor = 'pointer';
    }

    uncolor(mesh: Mesh): void {
        mesh.traverse((object: Object3D) => {
            if (object instanceof LineSegments) {
                //object.material = new LineBasicMaterial({color: 0x002855, linewidth: 1});
            }
        });
        mesh.material = new MeshBasicMaterial({ color: 0xFFFFFF });
        this.renderer.domElement.style.cursor = 'auto';
    }

    glstats() {
        let text = 'calls ' + this.renderer.info.render.calls;
        text += ' | points ' + this.renderer.info.render.points;
        text += ' | triangles ' + this.renderer.info.render.triangles;
        text += ' | geometries ' + this.renderer.info.memory.geometries;
        text += ' | textures ' + this.renderer.info.memory.textures;
        text += ' | programs ' + this.renderer.info.programs.length;
        //document.getElementById('gl_stats_container').innerText = text;
    }

    render(): void {


        this.composer.render();

        //console.log(this.camera.position);
        //console.log(this.camera.rotation);

    }

}
