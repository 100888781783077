import { FC, useRef } from 'react'
import cn from 'classnames'
import useKeyDown from 'app/hooks/useKeyDown'
import {ReactComponent as ScrollIcon} from 'icons/scroll_down.svg'
import classes from './Title.module.scss'

type TitleProps = {
  onClose: () => void
}

export const Title: FC<TitleProps> = ({ onClose }) => {
  const scrolledRef = useRef(false)

  useKeyDown('Space', onClose)

  const handleWheel = () => {
    if (scrolledRef.current) {
      return
    }

    scrolledRef.current = true
    onClose()
  }

  return (
    <div className={classes.title_page} onWheel={handleWheel} onClick={onClose}>
      <div className={classes.content}>
        <h1 className={cn(classes.fade, classes.title)}>Приразломная</h1>
        <div className={cn(classes.fade, classes.description)}>
          Виртуальный тур по платформе
        </div>
        <button className={cn(classes.fade, classes.scroll_btn)}>
          <ScrollIcon className={classes.scroll_icon} />
        </button>
      </div>
    </div>
  )
}
