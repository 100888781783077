import { Link } from 'react-router-dom'
import cn from 'classnames'
import Logos from 'app/components/Logos/Logos'
import compass from 'images/compass_blue.svg'
import classes from './Header.module.scss'

type HeaderProps = {
	className?: string
	sitemap: boolean
	onOpenSiteMap: () => void
	onCloseSiteMap: () => void
}

const Header: React.FC<HeaderProps> = ({
	className,
	sitemap,
	onOpenSiteMap,
	onCloseSiteMap,
}) => {
	return (
		<header className={cn(className, classes.header)}>
			<div className={classes.container}>
				<div className={classes.compass_box}>
					<div id='rotateCompas' className={classes.compass_rotate}>
						<img
							className={classes.compass}
							src={compass}
							alt=''
							onClick={sitemap ? onCloseSiteMap : onOpenSiteMap}
						/>
					</div>
				</div>
				<Link className={classes.title} style={{ color: '#002855' }} to='/'>
					Приразломная
				</Link>
				<Logos className={classes.logos} white={false} />
			</div>
		</header>
	)
}

export default Header
