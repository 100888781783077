import { Action } from './actions'
import {
  ACTIVATE_NEXT_SLIDE,
  ACTIVATE_PREV_SLIDE,
  ACTIVATE_SLIDE,
  LOAD_SLIDER_BEGIN,
  LOAD_SLIDER_FAIL,
  LOAD_SLIDER_SUCCESS,
} from './constants'
import { SliderState } from './model'
import getNextId from './utils/getNextId'
import getPrevId from './utils/getPrevId'

const initialState: SliderState = {
  isLoading: false,
  error: null,
  data: [],
}

const sliderReducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case LOAD_SLIDER_BEGIN:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case LOAD_SLIDER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
      }
    case LOAD_SLIDER_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      }
    case ACTIVATE_PREV_SLIDE: {
      const prevId = getPrevId(state.data)

      return {
        ...state,
        data: state.data.map(el =>
          el.id === prevId ? { ...el, active: true } : { ...el, active: false }
        ),
      }
    }
    case ACTIVATE_SLIDE:
      return {
        ...state,
        data: state.data.map(el =>
          action.payload.id === el.id
            ? { ...el, active: true }
            : { ...el, active: false }
        ),
      }
    case ACTIVATE_NEXT_SLIDE:
      const nextId = getNextId(state.data)

      return {
        ...state,
        data: state.data.map(el =>
          el.id === nextId ? { ...el, active: true } : { ...el, active: false }
        ),
      }
    default:
      return state
  }
}

export default sliderReducer
