import { FC } from 'react'
import { useSelector } from 'react-redux'
import cn from 'classnames'
import { logosSelector } from 'app/redux/logo/selectors'
import classes from './Logos.module.scss'

type LogosProps = {
  className?: string
  white: boolean
}

const Logos: FC<LogosProps> = ({ className, white }) => {
  const logos = useSelector(logosSelector)

  return (
    <ul className={cn(className, classes.logos)}>
      {logos.map(({ url, src_colored, src_white, alt }) => (
        <li key={url} className={classes.logo}>
          <a href={url} target='_blank' rel='noreferrer'>
            <img className={classes.logo_img} src={white ? src_white : src_colored} alt={alt} />
          </a>
        </li>
      ))}
    </ul>
  )
}

export default Logos
