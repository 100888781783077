import { Action } from './actions'
import {
  LOAD_ANIMATION_SLIDES_TEXT_BEGIN,
  LOAD_ANIMATION_SLIDES_TEXT_SUCCESS,
  LOAD_ANIMATION_SLIDES_TEXT_FAIL,
} from './constants'
import { AnimationState } from './model'

const initialState: AnimationState = {
  isLoading: false,
  error: null,
  slides: [],
}

const animationReducer = (state = initialState, action: Action): AnimationState => {
  switch (action.type) {
    case LOAD_ANIMATION_SLIDES_TEXT_BEGIN:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case LOAD_ANIMATION_SLIDES_TEXT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        slides: action.payload.slides,
      }
    case LOAD_ANIMATION_SLIDES_TEXT_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      }
    default:
      return state
  }
}

export default animationReducer
