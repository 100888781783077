import { ThunkDispatch } from 'redux-thunk'
import * as audioApi from 'api/audio'
import {
  LOAD_AUDIOS_BEGIN,
  LOAD_AUDIOS_SUCCESS,
  LOAD_AUDIOS_FAIL,
} from './constants'
import { Audio, AudioTypes } from './model'

const loadAudios = () => async (dispatch: ThunkDispatch<{}, {}, Action>) => {
  dispatch(loadAudiosBegin())

  try {
    const audios = await audioApi.loadAudios()

    dispatch(loadAudiosSuccess(audios.data, audios.type_list))
  } catch (error) {
    dispatch(loadAudiosFail(error as Error))
  }
}

const loadAudiosBegin = () =>
  ({
    type: LOAD_AUDIOS_BEGIN,
  } as const)

const loadAudiosSuccess = (data: Array<Audio>, types: AudioTypes) =>
  ({
    type: LOAD_AUDIOS_SUCCESS,
    payload: {
      data,
      types,
    },
  } as const)

const loadAudiosFail = (error: Error) =>
  ({
    type: LOAD_AUDIOS_FAIL,
    payload: {
      error,
    },
  } as const)

type LoadAudiosBegin = ReturnType<typeof loadAudiosBegin>
type LoadAudiosSuccess = ReturnType<typeof loadAudiosSuccess>
type LoadAudiosFail = ReturnType<typeof loadAudiosFail>

type Action = LoadAudiosBegin | LoadAudiosSuccess | LoadAudiosFail

export { loadAudios }
export type { Action }
