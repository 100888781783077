import { Link } from 'react-router-dom'
import { Header } from '../adult/header/Header'
import classes from './NotFound.module.css'

export const NotFound: React.FC = () => {
  const openSitemap = () => {}
  const closeSitemap = () => {}

  return (
    <div className={classes.not_found_page}>
      <Header
        displayLogo
        modal
        openSitemap={openSitemap}
        closeSitemap={closeSitemap}
        sitemap={false}
      />
      <div className={classes.container}>
        {/* <div className={classes.page_subtitle}>
          Виртуальный тур по платформе
        </div> */}
        <div className={classes.page_content}>
          <div className={classes.page_title}>404</div>
          <div className={classes.divider}></div>
          <div className={classes.page_description}>
            Запрашиваемая вами страница не найдена. <br />
            <br /> Льды имеют свойство дрейфовать, возвращайтесь в безопасное{' '}
            <Link to='/'>место</Link>.
          </div>
        </div>
      </div>
    </div>
  )
}
