import { FC, useEffect, useState } from 'react'
import toMMSS from 'lib/toMMSS'
import useAudioGuide from 'app/hooks/useAudioGuide'
import { ReactComponent as AudioWaveIcon } from 'icons/audio-wave.svg'
import { ReactComponent as PlayIcon } from './icons/play.svg'
import { ReactComponent as PauseIcon } from './icons/pause.svg'
import classes from './AudioGuide.module.scss'

const options = {
  height: 4,
  progressColor: '#005CB9',
} as const

type AudioGuideProps = {
  src: string
}

const AudioGuide: FC<AudioGuideProps> = ({ src }) => {
  const {
    waveformContainerRef,
    isPlaying,
    isLoading,
    duration,
    progress,
    handlePlay,
    handlePause,
  } = useAudioGuide(src, options)

  const [scaling, setScaling] = useState(0)

  useEffect(() => {
    setScaling(window.screen.width / 1920)
  }, [window.screen.width])

  return (
    <div className={classes.guide}>
      <div className={classes.guide_body}>
        <div className={classes.guide_timeline_wrapper}>
          <div ref={waveformContainerRef} className={classes.guide_timeline} style={{ transform: `scale(${scaling})` }}>
            {isLoading && (
              <div className={classes.guide_timeline_loader}>
                <AudioWaveIcon />
              </div>
            )}
          </div>
        </div>
        <button
          className={classes.control}
          disabled={isLoading}
          onClick={isPlaying ? handlePause : handlePlay}
        >
          {isPlaying ? <PauseIcon /> : <PlayIcon />}
        </button>
      </div>
      <div className={classes.guide_title}>
        Аудиогид{!isLoading && <span>. {toMMSS(duration - progress)}</span>}
      </div>
    </div>
  )
}

export default AudioGuide
