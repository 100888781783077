import { FC, useEffect, useState } from 'react'
import cn from 'classnames'
import PrettyScrollbars from 'app/components/PrettyScrollbars/PrettyScrollbars'
import useVideoViewer from './hooks/useVideoViewer'
import classes from './VideoViewer.module.scss'

type VideoViewerProps = {
  className?: string
  src: string
  description?: string
}

const VIDEO_VIEWER_NAME = 'video-viewer'

const VideoViewer: FC<VideoViewerProps> = ({ className, src, description }) => {
  useVideoViewer({
    iframeName: VIDEO_VIEWER_NAME,
    src,
    autoPlay: true,
  })

  const [isDescriptionDisplayed, setIsDescriptionDisplayed] = useState(false)

  useEffect(() => {
    setIsDescriptionDisplayed(false)
  }, [src])

  const toggleIsDescriptionDisplayed = () => {
    setIsDescriptionDisplayed(isOpened => !isOpened)
  }

  return (
    <div className={cn(className, classes.container)}>
      {description && (
        <>
          <button
            className={classes.toggle}
            onClick={toggleIsDescriptionDisplayed}
          />
          {isDescriptionDisplayed && (
            <div className={classes.description}>
              <div className={classes.content}>
                <PrettyScrollbars hide autoScroll>
                  <p>{description || 'Здесь еще нет описания'}</p>
                </PrettyScrollbars>
              </div>
            </div>
          )}
        </>
      )}
      <iframe
        name={VIDEO_VIEWER_NAME}
        className={classes.viewer}
        src={src}
        title='Video viewer'
        frameBorder='0'
        allow='fullscreen'
      />
    </div>
  )
}

export default VideoViewer
