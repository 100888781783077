import { SliderItem } from 'app/redux/slider/model'
import { slidesSelector } from 'app/redux/slider/selectors'
import { useSelector } from 'react-redux'

const findParent = (
  slides: Array<SliderItem>,
  ids: Array<string>
): SliderItem | null => {
  const idsSet = new Set(ids)
  return (
    slides.find(({ children }) => {
      return children.some(({ id }) => idsSet.has(id))
    }) ?? null
  )
}

const useFindParentSlide = () => {
  const slides = useSelector(slidesSelector)

  const findParentSlide = (ids: Array<string>) => {
    return findParent(slides, ids)
  }

  return {
    findParentSlide,
  }
}

export default useFindParentSlide
