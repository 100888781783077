import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { mainBackgroundAudioSelector } from 'app/redux/audio/selectors'
import useAudio from 'app/hooks/useAudio'

const useBackgroundAudio = (needToPlay: boolean) => {
  const bgAudioSrc = useSelector(mainBackgroundAudioSelector)

  const { isPlaying, play, pause } = useAudio(bgAudioSrc, {
    loop: true,
    volume: 0.5,
  })

  useEffect(() => {
    if (needToPlay) {
      play(true)
    } else {
      pause(true)
    }
  }, [needToPlay])

  return {
    isPlaying
  }
}

export default useBackgroundAudio
