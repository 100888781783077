import appendStyle from 'lib/appendStyle'
import { useEffect, useRef, useState } from 'react'

type Options = {
  iframeName: string
  src: string
  autoPlay?: boolean
}

const useVideoViewer = ({ iframeName, src, autoPlay = false }: Options) => {
  const iframeWindowRef = useRef<Window>()
  const [isInjected, setIsInjected] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    const handleFullScreenChange = () => {
      // Это событие ловит Renderer в AnimationEngine.js
      // Нужно чтобы после закрытия iframe анимация оставалсь на текущем слайде,
      // а не пролистывалась в начало анимации
      window.dispatchEvent(new Event('closeiframe'))
    }

    document.addEventListener('fullscreenchange', handleFullScreenChange)
    document.addEventListener('webkitfullscreenchange', handleFullScreenChange)
    document.addEventListener('mozfullscreenchange', handleFullScreenChange)

    return () => {
      document.removeEventListener('fullscreenchange', handleFullScreenChange)
      document.removeEventListener(
        'webkitfullscreenchange',
        handleFullScreenChange
      )
      document.removeEventListener(
        'mozfullscreenchange',
        handleFullScreenChange
      )
    }
  }, [iframeName, src])

  useEffect(() => {
    setIsInjected(false)
    setIsLoaded(false)

    const intervalId = setInterval(() => {
      const iframeWindow = window.frames[iframeName] as Window | undefined

      if (!iframeWindow) {
        return
      }

      iframeWindowRef.current = iframeWindow
      clearInterval(intervalId)
      setIsInjected(true)
    }, 500)

    return () => {
      clearInterval(intervalId)
    }
  }, [src])

  useEffect(() => {
    if (!isInjected) {
      return
    }

    if (iframeWindowRef.current.document.readyState === 'complete') {
      setIsLoaded(true)
      return
    }

    const handleLoaded = () => {
      setIsLoaded(true)
    }

    iframeWindowRef.current.addEventListener('load', handleLoaded)

    return () => {
      iframeWindowRef.current.removeEventListener('load', handleLoaded)
    }
  }, [isInjected])

  useEffect(() => {
    if (!isLoaded) {
      return
    }

    const style = `
    /* it hides progress bar */
    #\\33 0 { display: none !important;  }
    `

    appendStyle(iframeWindowRef.current.document.head, style)
  }, [isLoaded])

  useEffect(() => {
    if (!isLoaded || !autoPlay) {
      return
    }

    play()
  }, [isLoaded, autoPlay])

  const play = () => {
    if (!isLoaded) {
      return
    }

    // @ts-expect-error bad typing
    iframeWindowRef.current?.resumeTour()
  }

  const pause = () => {
    if (!isLoaded) {
      return
    }

    // @ts-expect-error bad typing
    iframeWindowRef.current?.pauseTour()
  }
}

export default useVideoViewer
