import { useEffect } from 'react'

const useResetWindowScroll = () => {
  useEffect(() => {
    const handleScrollToTop = () => {
      window.scrollTo({
        top: 0,
      })
    }

    window.addEventListener('beforeunload', handleScrollToTop)

    return () => {
      window.removeEventListener('beforeunload', handleScrollToTop)
    }
  }, [])
}

export default useResetWindowScroll
