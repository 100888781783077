import { SliderItem } from '../model'

const getPrevId = (data: Array<SliderItem>) => {
  let currentIndex = data.findIndex(({ active }) => active)

  if (currentIndex === -1) {
    return data[0].id
  }

  const prevIndex = currentIndex - 1

  if (prevIndex < 0) {
    return data[data.length - 1].id
  }

  return data[prevIndex].id
}

export default getPrevId
