import { FC } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import routes from 'app/routes'
import { sortedSlidesSelector } from 'app/redux/slider/selectors'
import classes from './Sitemap.module.scss'

type SitemapProps = {
  onSelect: (id: string) => void
}

export const Sitemap: FC<SitemapProps> = ({ onSelect }) => {
  const slides = useSelector(sortedSlidesSelector)

  const handleReload = () => {
    window.location.reload()
  }

  return (
    <div className={classes.sitemap_page}>
      <div className={classes.sitemap_content}>
        <h2 className={classes.sitemap_title}>Карта сайта</h2>
        <div className={classes.slides}>
          {slides.map(({ id, title }) => (
            <button
              key={id}
              className={classes.slide_item}
              onClick={() => onSelect(id)}
            >
              {title}
            </button>
          ))}
          <div className={classes.nav}>
          <Link
            className={classes.nav_item}
            to={routes.main}
            onClick={handleReload}
          >
            Вернуться на главную
          </Link>
          <Link className={classes.nav_item} to={routes.kids}>
            Взглянуть иначе
          </Link>
          </div>
        </div>
      </div>
    </div>
  )
}
