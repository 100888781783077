import { FC } from 'react'
import { Scrollbars } from 'react-custom-scrollbars-2'
import useAutoScrolling from './hooks/useAutoScrolling'

type PrettyScrollbarsProps = {
  hide?: boolean
  autoHide?: boolean
  autoScroll?: boolean
}

const PrettyScrollbars: FC<PrettyScrollbarsProps> = ({
  hide = false,
  autoHide = false,
  autoScroll = false,
  children,
}) => {
  const { scrollbarsRef } = useAutoScrolling(autoScroll)

  if (hide) {
    return (
      <Scrollbars ref={scrollbarsRef} renderTrackVertical={() => <div/>}>
        {children}
      </Scrollbars>
    )
  }

  return (
    <Scrollbars ref={scrollbarsRef} autoHide={autoHide}>
      {children}
    </Scrollbars>
  )
}

export default PrettyScrollbars
