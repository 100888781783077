import { useEffect, useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import routes from 'app/routes'
import { loadLogos } from 'app/redux/logo/actions'
import { loadAnimationSlidesText } from 'app/redux/animation/actions'
import { loadAudios } from 'app/redux/audio/actions'
import { loadSlider } from 'app/redux/slider/actions'
import WarningForMobile from 'app/components/WarningForMobile/WarningForMobile'
import Home from './home/Home'
import Adult from './adult/Adult'
import Children from './children/Children'
import { NotFound } from './404/NotFound'

const App = () => {
  const [isLogged, setIsLogged] = useState(false)

  const dispatch = useDispatch()

  const checkout = () => {
    const writtenPassword = prompt()

    if (writtenPassword === 'qwerty71') {
      setIsLogged(true)
    }
    else {
      checkout()
    }
  }

  useEffect(() => {
    dispatch(loadLogos())
    dispatch(loadAnimationSlidesText())
    dispatch(loadAudios())
    dispatch(loadSlider())

    checkout()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div style={!isLogged ? { display: 'none' } : {}}>
      {/* <> */}
      <WarningForMobile />
      <Routes>
        <Route path={routes.home} element={<Home />} />
        <Route path={routes.main} element={<Adult />} />
        <Route path={routes.kids} element={<Children />} />
        <Route path='*' element={<NotFound />} />
      </Routes>
      {/* </> */}
    </div>
  )
}

export default App
