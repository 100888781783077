import { combineReducers } from 'redux'
import logoReducer from './logo/reducer'
import animationReducer from './animation/reducer'
import audioReducer from './audio/reducer'
import sliderReducer from './slider/reducer'

const rootReducer = combineReducers({
  logo: logoReducer,
  animation: animationReducer,
  audio: audioReducer,
  slider: sliderReducer,
})

export default rootReducer
