import PrettyScrollbars from 'app/components/PrettyScrollbars/PrettyScrollbars'
import cn from 'classnames'
import classes from './SlideText.module.scss'

type SlideTextProps = {
  className?: string
  slide: number
  text: string
}

const SlideText: React.FC<SlideTextProps> = ({ className, slide, text }) => {
  return (
    <div
      className={cn(className, classes.text, classes[`text_${slide}`])}
      onWheel={e => e.stopPropagation()}
    >
      <PrettyScrollbars hide autoScroll>
        <div className={classes.text_inner}>{text}</div>
      </PrettyScrollbars>
    </div>
  )
}

export default SlideText
