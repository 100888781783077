import gsap from 'gsap'
import { horizontalflow } from './components/horizontalFlow/horizontalFlow'
import { mainLetters } from './components/mainLetters/mainLetters'
import { platformMenu } from './components/platformMenu/platformMenu'
import { timeLaps } from './components/timeLaps/timeLaps'

import NewScroll from './utils/scroll'
import { createScreensHeight } from './utils/functions'

let subscribers = []

const on = (event, cb) => subscribers.push({ event, cb })
const un = (event, cb) => {
  if (subscribers.length)
    subscribers = subscribers.filter((s) => s.event !== event || s.cb !== cb)
}

class Renderer {
  swipeDuration = 1500
  timeline = gsap.timeline()

  mouse = {
    moving: false,
    x: 0,
    y: 0,
  }
  scroll = {
    scrolling: false,
    parallax: false,
    pageYMax: 0,
  }
  screenScrollProps = {
    isActive: false,
    screensHeight: [],
    activeScreen: 0,
    direction: null,
    swiping: false,
    pageY: null,
    prevWheeling: false,
    wheeling: false,
    wheelingTimeout: null,
  }
  constructor() {
    this.handlers = []
    this.screenScrollProps.pageY = null
    window.scrollTo({ top: 0 })
  }

  setHandler(handler) {
    this.handlers.push(handler)
  }

  unsetHandler(handler) {
    this.handlers = this.handlers.filter((hd) => hd !== handler)
  }

  setMousePosition(mouse) {
    this.mouse = mouse
  }

  setScrollPosition(scroll) {
    this.screenScrollProps.wheeling = true

    clearTimeout(this.screenScrollProps.wheelingTimeout)
    this.screenScrollProps.wheelingTimeout = setTimeout(
      function ($this) {
        $this.screenScrollProps.wheeling = false
      },
      100,
      this
    )

    this.screenScrollProps.direction = scroll.direction || null
    this.mouse.moving = false
  }

  setScreens() {
    this.screenScrollProps.isActive = true
  }

  render() {
    requestAnimationFrame(
      function animate() {
        this.handlers.forEach(hd =>
          hd(this.mouse, this.scroll, this.swipeDuration)
        )

        if (this.scroll.pageYMax < window.scrollY)
          this.scroll.pageYMax = window.scrollY
        requestAnimationFrame(animate.bind(this))
        this.scroll.parallax = false

        this.scrollScreen()
      }.bind(this)
    )
  }

  scrollScreen() {
    if (!this.screenScrollProps.isActive) return
    if (this.screenScrollProps.swiping) return
    if (!this.screenScrollProps.wheeling)
      this.screenScrollProps.prevWheeling = false
    if (!this.screenScrollProps.wheeling) return
    if (this.screenScrollProps.prevWheeling) return
    if (this.screenScrollProps.direction === null) return

    this.screenScrollProps.screensHeight = createScreensHeight()
    this.screenScrollProps.activeScreen += this.screenScrollProps.direction * -1

    if (
      this.screenScrollProps.activeScreen >=
      this.screenScrollProps.screensHeight.length
    ) {
      --this.screenScrollProps.activeScreen
      return
    }
    if (this.screenScrollProps.activeScreen < 0) {
      ++this.screenScrollProps.activeScreen
      return
    }

    this.scroll.scrolling = true
    this.screenScrollProps.prevWheeling = true
    this.screenScrollProps.swiping = true
    this.scroll.parallax = true
    console.log('SWIPE!!!')

    subscribers.forEach(({ event, cb }) => {
      if (event === 'changeActiveScreen' && cb) {
        cb(this.screenScrollProps.activeScreen)
      }
    })

    let scrollPosition = 0
    for (let i = 0; i <= this.screenScrollProps.activeScreen; i++) {
      scrollPosition += this.screenScrollProps.screensHeight[i]
    }

    gsap.to(
      { y: window.pageYOffset },
      {
        y: scrollPosition,
        onUpdate() {
          window.scrollTo({
            top: this.targets()[0].y,
          })
        },
        ease: 'sin.inOut',
        duration: this.swipeDuration / 1000,
      }
    )

    setTimeout(
      function ($this) {
        $this.screenScrollProps.swiping = false
        $this.scroll.scrolling = false
        console.log('---> Ready to swipe!')
      },
      this.swipeDuration,
      this
    )
  }

  toStart() {
    if (this.screenScrollProps.activeScreen < 2) {
      return
    }

    this.screenScrollProps.prevWheeling = false
    this.screenScrollProps.wheeling = true
    this.screenScrollProps.direction = 1 // to scroll to the prev slide
    this.screenScrollProps.screensHeight = createScreensHeight()
    this.screenScrollProps.activeScreen = 2 // set the current slide as the second slide
    this.scrollScreen()
  }

  toEnd() {
    this.screenScrollProps.prevWheeling = false
    this.screenScrollProps.wheeling = true
    this.screenScrollProps.direction = -1 // to scroll to the next slide
    this.screenScrollProps.screensHeight = createScreensHeight()
    this.screenScrollProps.activeScreen =
      this.screenScrollProps.screensHeight.length - 2 // set the current slide as the penultimate slide
    this.scrollScreen()
  }
}

let newScroll = null
let renderer = null

const init = () => {
  newScroll = new NewScroll()
  newScroll.mouseWheel()
  newScroll.touchMove()
  newScroll.spaceKeyDown()

  renderer = new Renderer()
  renderer.setHandler(mainLetters)
  renderer.setHandler(horizontalflow)
  renderer.setHandler(timeLaps)
  renderer.setHandler(platformMenu)
  renderer.setScreens()
  renderer.render()

  document.addEventListener('mousemove', setMousePosition)
  document.addEventListener('scroll', animateByScroll)

  window.onbeforeunload = function () {
    window.scrollTo(0, 0)
  }

  window.addEventListener('closeiframe', () => {
    window.scrollTo({ top: renderer.scroll.pageYMax })
  })

  return renderer
}

const disableEventListener = () => {
  document.removeEventListener('mousemove', setMousePosition)
  document.removeEventListener('scroll', animateByScroll)
  console.log(1111111111111111)
}

const enableEventListener = () => {
  document.addEventListener('mousemove', setMousePosition)
  document.addEventListener('scroll', animateByScroll)
}

const destroy = () => {
  newScroll = null
  renderer = null
  subscribers = []

  document.removeEventListener('mousemove', setMousePosition)
  document.removeEventListener('scroll', animateByScroll)
}

const setMousePosition = function (e) {
  renderer.setMousePosition({
    moving: true,
    x: e.clientX,
    y: e.clientY,
  })
}

const animateByScroll = function (e) {
  if (e.detail === undefined) return
  renderer.setScrollPosition({
    direction: e.detail.direction,
  })
}

export { init, destroy, on, un, enableEventListener, disableEventListener }
