import { nanoid } from 'nanoid'
import { loadVideos } from 'api/video'
import { ThunkDispatch } from 'redux-thunk'
import {
  ACTIVATE_PREV_SLIDE,
  ACTIVATE_SLIDE,
  ACTIVATE_NEXT_SLIDE,
  LOAD_SLIDER_BEGIN,
  LOAD_SLIDER_FAIL,
  LOAD_SLIDER_SUCCESS,
} from './constants'
import { SliderItem } from './model'

const loadSlider = () => async (dispatch: ThunkDispatch<{}, {}, Action>) => {
  dispatch(loadSliderBegin())

  try {
    const videos = await loadVideos()

    dispatch(
      loadSliderSuccess(
        videos.map(
          ({
            name,
            title,
            text_main,
            text_kids,
            audio_kids,
            audio_main,
            url,
            is_internal,
            children,
          }) => ({
            id: nanoid(),
            name,
            title,
            text_main,
            text_kids,
            audio_kids,
            audio_main,
            url,
            is_internal,
            active: false,
            children: children.map(({ number, name }) => ({
              id: number,
              name,
            })),
          })
        )
      )
    )
  } catch (error) {
    dispatch(loadSliderFail(error as Error))
  }
}

const loadSliderBegin = () =>
  ({
    type: LOAD_SLIDER_BEGIN,
  } as const)

const loadSliderSuccess = (data: Array<SliderItem>) =>
  ({
    type: LOAD_SLIDER_SUCCESS,
    payload: {
      data,
    },
  } as const)

const loadSliderFail = (error: Error) =>
  ({
    type: LOAD_SLIDER_FAIL,
    payload: {
      error,
    },
  } as const)

const activatePrevSlide = () =>
  ({
    type: ACTIVATE_PREV_SLIDE,
  } as const)

const activateSlide = (id: string) =>
  ({
    type: ACTIVATE_SLIDE,
    payload: {
      id,
    },
  } as const)

const activateNextSlide = () =>
  ({
    type: ACTIVATE_NEXT_SLIDE,
  } as const)

type LoadSliderBegin = ReturnType<typeof loadSliderBegin>
type LoadSliderSuccess = ReturnType<typeof loadSliderSuccess>
type LoadSliderFail = ReturnType<typeof loadSliderFail>

type ActivatePrevSlideAction = ReturnType<typeof activatePrevSlide>
type ActivateSlideAction = ReturnType<typeof activateSlide>
type ActivateNextSlideAction = ReturnType<typeof activateNextSlide>

type Action =
  | LoadSliderBegin
  | LoadSliderSuccess
  | LoadSliderFail
  | ActivatePrevSlideAction
  | ActivateSlideAction
  | ActivateNextSlideAction

export { loadSlider, activatePrevSlide, activateSlide, activateNextSlide }
export type { Action }
