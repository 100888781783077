import { useDispatch, useSelector } from 'react-redux'
import { activateSlide } from 'app/redux/slider/actions'
import {
  activeSlideSelector,
  sortedSlidesSelector,
} from 'app/redux/slider/selectors'
import AudioGuide from './AudioGuide/AudioGuide'
import VideoViewer from 'app/components/VideoViewer/VideoViewer'
import VerticalCarousel from './VerticalCarousel/VerticalCarousel'
import classes from './Slides.module.scss'

type SlidesProps = {
  closeModal: () => void
}

const Slides: React.FC<SlidesProps> = ({ closeModal }) => {
  const slides = useSelector(sortedSlidesSelector)
  const activeSlide = useSelector(activeSlideSelector)

  const dispatch = useDispatch()

  const handleActiveItem = (id: string) => {
    dispatch(activateSlide(id))
  }

  return (
    <div className={classes.slides_modal}>
      <AudioGuide mode={'white'} src={activeSlide.audio_main} title={activeSlide.title} />
      <div className={classes.slider_main}>
        <div className={classes.slides_title}>{activeSlide.title}</div>
        <div className={classes.back_btn}>
          <button onClick={closeModal}>
            Назад
          </button>
        </div>
        <div className={classes.slider_video}>
          <VideoViewer
            src={activeSlide.url}
            description={activeSlide.text_main}
          />
        </div>
        <VerticalCarousel
          className={classes.carousel}
          defaultActiveId={activeSlide.id}
          data={slides}
          onActivate={handleActiveItem}
        />
      </div>
    </div>
  )
}

export default Slides
