import { FC, useEffect } from 'react'

type ImagePreloaderProps = {
  src: string
  onLoaded?: () => void
}

const ImagePreloader: FC<ImagePreloaderProps> = ({ src, onLoaded }) => {
  useEffect(() => {
    const preloader = new Image()

    if (onLoaded) {
      preloader.onload = onLoaded
    }

    preloader.src = src
  }, [src, onLoaded])

  return null
}

export default ImagePreloader
