import { FC, useRef, useState, useMemo, useEffect } from 'react'
import cn from 'classnames'
import Slider, { Settings } from 'react-slick'
import { ReactComponent as ArrowTopIcon } from 'icons/arrow-top-filled.svg'
import { ReactComponent as ArrowBottomIcon } from 'icons/arrow-bottom-filled.svg'
import classes from './VerticalCarousel.module.scss'

const ASPECT_RATIO = window.innerWidth / window.innerHeight
const SLIDES_TO_SHOW = ASPECT_RATIO > 2 ? 4 : 6

type VerticalCarouselProps = {
  className?: string
  defaultActiveId: string
  data: Array<{
    id: string
    title: string
  }>
  onActivate: (id: string) => void
}

const VerticalCarousel: FC<VerticalCarouselProps> = ({
  className,
  defaultActiveId,
  data,
  onActivate,
}) => {
  const sliderRef = useRef<Slider>(null)
  const [activeSlideId, setActiveSlideId] = useState(defaultActiveId)
  const [currentSlick, setCurrentSlick] = useState(
    data.findIndex(({ id }) => id === defaultActiveId) ?? 0
  )

  useEffect(() => {
    sliderRef.current.slickGoTo(
      data.findIndex(({ id }) => id === defaultActiveId) ?? 0
    )
    setActiveSlideId(defaultActiveId)
  }, [defaultActiveId])

  const settings = useMemo((): Settings => {
    return {
      infinite: true,
      slidesToShow: SLIDES_TO_SHOW,
      vertical: true,
      arrows: false,
      speed: 200,
      beforeChange: (_, i) => {
        setCurrentSlick(i)
      },
    }
  }, [])

  const handlePrev = () => {
    const lastIndex = data.length - 1

    if (lastIndex - currentSlick < SLIDES_TO_SHOW) {
      sliderRef.current.slickGoTo(lastIndex - SLIDES_TO_SHOW - 1)
    } else {
      sliderRef.current.slickGoTo(currentSlick - 2)
    }
  }

  const handleNext = () => {
    sliderRef.current.slickGoTo(currentSlick + 2)
  }

  const handleClick = (id: string) => {
    sliderRef.current.slickGoTo(data.findIndex(slide => slide.id === id) - 1)
    onActivate(id)
  }

  return (
    <div className={className}>
      <button className={classes['prev']} type='button' onClick={handlePrev}>
        {/* <ArrowTopIcon /> */}
      </button>
      <div className={classes['slides']}>
        <Slider {...settings} ref={sliderRef}>
          {data.map(({ id, title }) => (
            <div key={id}>
              <button
                className={cn(classes['slide'], {
                  [classes['active']]: activeSlideId === id,
                })}
                onClick={() => handleClick(id)}
              >
                {title}
              </button>
            </div>
          ))}
        </Slider>
      </div>
      <button className={classes['next']} type='button' onClick={handleNext}>
        {/* <ArrowBottomIcon /> */}
      </button>
    </div>
  )
}

export default VerticalCarousel
