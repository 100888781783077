import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Logos from 'app/components/Logos/Logos'
import compass from 'images/compass.svg'
import classes from './Header.module.scss'

interface IProps {
  displayLogo: boolean
  modal: boolean
  sitemap: boolean
  closeSitemap: () => void
  openSitemap: () => void
}

export const Header: React.FC<IProps> = ({
  displayLogo,
  modal,
  closeSitemap,
  openSitemap,
  sitemap,
}) => {
  const secondScreen = window.innerHeight * 0.95

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true })

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const [scrollPosition, setScrollPosition] = useState(0)

  const handleScroll = () => {
    const position = window.pageYOffset
    setScrollPosition(position)
  }

  return (
    <header className={classes.header}>
      <div
        className={
          scrollPosition >= secondScreen || modal
            ? classes.compass_box
            : [classes.compass_box, classes.invisible].join(' ')
        }
      >
        <div id='rotateCompas' className={classes.compass_rotate}>
          <img
            src={compass}
            className={
              modal && !sitemap
                ? [classes.compass, classes.white].join(' ')
                : classes.compass
            }
            onClick={() => (sitemap ? closeSitemap() : openSitemap())}
          />
        </div>
      </div>
      <Link
        className={
          scrollPosition >= secondScreen || modal
            ? classes.title
            : [classes.title, classes.invisible].join(' ')
        }
        style={
          modal && !sitemap ? { color: '#ffffff' } : { color: '#002855' }
        }
        to='/'
      >
        Приразломная
      </Link>
      {displayLogo && !modal && (
        <Logos className={classes.logos} white={modal && !sitemap} />
      )}
      <div className={
        !sitemap && scrollPosition >= secondScreen || modal
          ? classes.subtitle
          : [classes.subtitle, classes.invisible].join(' ')
      }
        style={
          modal && !sitemap ? { color: '#ffffff' } : { color: '#002855' }
        }>
        Виртуальный тур <br /> по платформе
      </div>
    </header>
  )
}
