import { RootState } from '../store'

export const mainBackgroundAudioSelector = (state: RootState): string | null => {
  const backgroundAudio = state.audio.data.find(({ type }) => type === 1)

  if (!backgroundAudio) {
    return null
  }

  return backgroundAudio.url
}

export const kidsBackgroundAudioSelector = (state: RootState): string | null => {
  const backgroundAudio = state.audio.data.find(({ type }) => type === 2)

  if (!backgroundAudio) {
    return null
  }

  return backgroundAudio.url
}
